import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../helpers/axios";

export const registerUser = createAsyncThunk(
  "users/register",
  async (user, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post("/auth/users/v1/signup", user);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const LogUserIn = createAsyncThunk(
  "users/login",
  async (user, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post("/auth/users/v1/signin", user);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getImages = createAsyncThunk("images/get", async () => {
  const res = await axiosInstance.get("storage/project/files/");
  return res.data;
});

export const getUserDetails = createAsyncThunk("customer/info", async () => {
  const token = localStorage.getItem("token");
  const headers = {
    " Authorization": `Bearer ` + token,
    "Content-Type": "application/json",
  };
  const res = await axiosInstance.get("/customer/me/info", {
    headers,
  });
  return res.data;
});

export const updateUser = createAsyncThunk(
  "update/user",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        " Authorization": `Bearer ` + token,
        "Content-Type": "application/json",
      };
      const res = await axiosInstance.post("/customer/me/update", data, {
        headers,
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      //   businessName: "",
      //   firstName: "",
      //   lastName: "",
      //   location: "",P
      //   email: "",
      //   password: "",
    },
    images: [],

    pending: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.user = payload.data;
    });

    builder.addCase(registerUser.rejected, (state) => {
      state.pending = null;
    });
    builder.addCase(getImages.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(getImages.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.images = payload.data;
      localStorage.setItem(
        "images",
        JSON.stringify({
          data: payload.data,
        })
      );
    });
    builder.addCase(getImages.rejected, (state) => {
      state.pending = null;
    });

    builder.addCase(getUserDetails.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.user = payload.data;
      localStorage.setItem("customerId", payload.data.id);
      localStorage.setItem("kyc", payload.data.status);
    });
    builder.addCase(getUserDetails.rejected, (state) => {
      state.pending = null;
    });
  },
});

export default userSlice.reducer;
