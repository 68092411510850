import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import logo2 from '../assets/images/logos/Mega Fortune Logo Round.png'

const Disclaimer = ({ isOpen, setIsOpen, message }) => {
  const closeModal = () => {
    setIsOpen(false)
  }

  // const openModal = () => {
  //   setIsOpen(true)
  // }
  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-70' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-2xl -mt-36 transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='flex flex-col justify-center items-center mt-8'
                  >
                    <img className='lg:h-24 md:h-[72px] h-[56px] mb-2' src={logo2} alt='Mega Fortune Logo' />
                    <h2 className='lg:text-[49px] md:text-[39px] text-[24px] font-tommy text-center'>
                      Browsing on Safari?
                    </h2>
                  </Dialog.Title>
                  <div className='mt-2 px-12 mb-8'>
                    <p className='text-center font-sans md:text-xl text-base'>
                      {message}
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default Disclaimer
