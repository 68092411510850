import { motion } from 'framer-motion'
import banner from '../assets/images/recBg.svg'
import phone from '../assets/images/icons/phone.svg'
import logo from '../assets/images/logos/Mega Fortune Logo Round.png'
import phoneBlack from '../assets/images/icons/phone-black.svg'
import web from '../assets/images/icons/web.svg'
import webBlack from '../assets/images/icons/web-black.svg'
import { Helmet } from 'react-helmet'
import { useState } from 'react'
function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}
const HowToPlay = () => {
  const [device, setDevice] = useState(1)

  const handleSetDevice = id => {
    setDevice(id)
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className=''
    >
      {process.env.REACT_APP_ENVIRONMENT_NAME === 'PRODUCTION' ? (
        <Helmet>
          <title>How to Play</title>
          <meta
            name='description'
            content='How to play Mega Fortune Lottery Games'
          />
        </Helmet>
      ) : null}
      <div className='bg-white pt-16 mt-2'>
        <div
          className='bg-cover w-full flex justify-center items-center bg-center h-[280px] bg-no-repeat'
          style={{ backgroundImage: `url(${banner})` }}
        >
          <div className='flex flex-col justify-center'>
            <p className='flex lg:text-4xl text-2xl justify-center text-white font-tommy'>
              HOW TO PLAY MEGA FORTUNE
            </p>
            <div className=' flex items-center mt-3 justify-center'>
              <p className=' text-center text-white'>
                Our games are available online and offline. <br /> You can play
                with our Mobile App, Website, and USSD. <br /> You can also play
                with an agent at an Orange Box near you.
              </p>
            </div>
          </div>
        </div>
        <div className=' flex justify-between md:w-4/12 px-6 py-12 mx-auto'>
          <div
            onClick={() => handleSetDevice(1)}
            className={classNames(
              device === 1
                ? 'flex justify-center items-center h-20 w-20 rounded-full border bg-gradient-to-b from-[#FF4100] to-[#FFAC00]'
                : 'flex justify-center items-center h-20 w-20 rounded-full border-[#FF4100] border',
              'cursor-pointer'
            )}
          >
            <img src={device === 1 ? phone : phoneBlack} alt='' />
          </div>
          <div
            onClick={() => handleSetDevice(2)}
            className={classNames(
              device === 2
                ? 'flex justify-center items-center h-20 w-20 rounded-full border bg-gradient-to-b from-[#FF4100] to-[#FFAC00]'
                : 'flex justify-center items-center h-20 w-20 rounded-full border-[#FF4100] border',
              'cursor-pointer'
            )}
          >
            <img src={device === 2 ? webBlack : web} alt='Mega Fortune Lottery Web App' />
          </div>
          <div
            onClick={() => handleSetDevice(3)}
            className={classNames(
              device === 3
                ? 'flex justify-center items-center h-20 w-20 rounded-full border bg-gradient-to-b from-[#FF4100] to-[#FFAC00] text-white'
                : 'flex justify-center items-center h-20 w-20 rounded-full border-[#FF4100] border',
              'cursor-pointer'
            )}
          >
            *824#
          </div>
        </div>
        <div>
          <div class='relative py-4 mx-24'>
            <div class='absolute inset-0 flex items-center'>
              <div class='w-full border-2 border-black'></div>
            </div>
            <div class='relative flex justify-center'>
              <span class='bg-white md:px-48 font-tommy md:text-3xl text-2xl uppercase py-2 text-center '>
                how to play -
                {device === 1
                  ? 'mobile app'
                  : device === 2
                  ? 'web app'
                  : 'ussd'}
              </span>
            </div>
          </div>

          {device === 1 ? (
            <p className=' text-center text-[#6B7280] text-sm'>
              Download the Mega Fortune Mobile App on the GooglePlay Store or
              the Apple App Store.
            </p>
          ) : null}
        </div>
        {device === 1 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className=''
          >
            <div className=''>
              <div className='grid md:grid-cols-4 grid-cols-1 py-12 gap-8 md:w-10/12 w-8/12 mx-auto '>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      1
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Click ‘PLAY NOW’ button once you open the App.
                  </p>
                </div>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      2
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    You can play your selected game as Mega 1, Mega 2, Mega 3,
                    Mega 4, Mega 5, Mega 6, Perm 2, Perm 3, Perm 4, Perm 5, Perm
                    6, or Banker.
                  </p>
                </div>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      3
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Pick your lucky number(s). You can play any number from 1 to
                    96.
                  </p>
                </div>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      4
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Enter the amount you want to play. You can play for as
                    little as GHS1, but no more than GHS 500.
                  </p>
                </div>
              </div>

              <div className='grid md:grid-cols-2 pb-12 gap-2 md:w-5/12 w-8/12 mx-auto'>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      5
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Click confirm and pay with your Mobile Money. You’ll receive
                    a prompt to authorise this payment.
                  </p>
                </div>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      6
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Mega Fortune will send you an SMS confirming your game.
                  </p>
                </div>
              </div>
              <div className='pb-24'>
                <div className=' flex justify-center items-center '>
                  <p className='flex justify-center items-center text-center font-tommy'>
                    <img className='h-6 px-2' src={logo} alt='Mega Fortune Logo' />
                    You can click ‘Add Game’ and repeat steps 2 to 4 as many
                    times as you want.
                  </p>
                </div>
                <div className=' flex justify-center items-center '>
                  <p className='flex justify-center items-center text-center font-tommy'>
                    <img className='h-6 px-2' src={logo} alt='Mega Fortune Logo' />
                    Your winnings will be paid directly to your registered
                    account.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        ) : device === 2 ? (
          <div>
            <div className=''>
              <div className='grid grid-cols-1 md:grid-cols-3 py-12 gap-8 w-8/12 mx-auto '>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      1
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Click the ‘GAMES’ tab to select the Game of the Day and
                    click ‘PLAY’.
                  </p>
                </div>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      2
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    You can play your selected game as Mega 1, Mega 2, Mega 3,
                    Mega 4, Mega 5, Mega 6, Perm 2, Perm 3, Perm 4, Perm 5, Perm
                    6, or Banker.
                  </p>
                </div>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      3
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Pick your lucky number(s). You can play any number from 1 to
                    96.
                  </p>
                </div>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      4
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Enter the amount you want to play. You can play for as
                    little as GHS1, but no more than GHS 500.
                  </p>
                </div>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      5
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Click confirm and pay with your Mobile Money. You’ll receive
                    a prompt to authorise this payment.
                  </p>
                </div>

                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      6
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Mega Fortune will send you an SMS confirming your game.
                  </p>
                </div>
              </div>

              <div className='pb-24'>
                <div className=' flex justify-center items-center '>
                  <p className='flex justify-center items-center text-center font-tommy'>
                    <img className='h-6 px-2' src={logo} alt='Mega Fortune Lottery' />
                    You can click ‘Add Game’ and repeat steps 2 to 4 as many
                    times as you want.
                  </p>
                </div>
                <div className=' flex justify-center items-center '>
                  <p className='flex justify-center items-center text-center font-tommy'>
                    <img className='h-6 px-2' src={logo} alt='Mega Fortune Lottery' />
                    Your winnings will be paid directly to your registered
                    account.
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : device === 3 ? (
          <div>
            <div className=''>
              <div className='grid grid-cols-1 md:grid-cols-3 py-12 gap-8 w-8/12 mx-auto '>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      1
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Dial *824# on your mobile phone and select ‘Toady’s Game’
                    when the prompt appears. No internet access is needed.
                  </p>
                </div>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      2
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    You can play your selected game as Mega 1, Mega 2, Mega 3,
                    Mega 4, Mega 5, Mega 6, Perm 2, Perm 3, Perm 4, Perm 5, Perm
                    6, or Banker.
                  </p>
                </div>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      3
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Pick your lucky number(s). You can play any number from 1 to
                    96.
                  </p>
                </div>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      4
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Enter the amount you want to play. You can play for as
                    little as GHS1, but no more than GHS 500.
                  </p>
                </div>
                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      5
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Click confirm and pay with your Mobile Money. You’ll receive
                    a prompt to authorise this payment.
                  </p>
                </div>

                <div>
                  <div className='flex items-center justify-center'>
                    <div className='h-8 w-8 flex mb-2 justify-center items-center rounded-full bg-black text-white'>
                      6
                    </div>
                  </div>
                  <p className='text-center text-[#6B7280] text-sm'>
                    Mega Fortune will send you an SMS confirming your game.
                  </p>
                </div>
              </div>

              <div className=' flex justify-center items-center pb-24'>
                <p className='flex justify-center items-center text-center font-tommy'>
                  <img className='h-6 px-2' src={logo} alt='Mega Fortune Lottery' />
                  Your winnings will be paid directly to your registered
                  account.
                </p>
              </div>
            </div>
          </div>
        ) : (
          'Something went wrong'
        )}
      </div>
    </motion.div>
  )
}

export default HowToPlay
