import bg from '../assets/images/bgstar.png'
import React, { Fragment, useState } from 'react'
import banner from '../assets/images/bg4.jpg'
import banner2 from '../assets/images/bg5.jpg'
import kiosk from '../assets/images/kiosk.jpg'
import android from '../assets/images/googleplay.png'
import apple from '../assets/images/appstore.png'
import phone from '../assets/images/hand_phone_image.png'
import dromo from '../assets/images/games/dromo.png'
import bingo from '../assets/images/games/bingo.png'
import happy from '../assets/images/games/mrhappy.png'
import flow from '../assets/images/games/overflow.png'
import sokoo from '../assets/images/games/sokooo.png'
import twadi from '../assets/images/games/twadi.png'
import cashout from '../assets/images/games/CASHOUT.png'
import { Link } from 'react-router-dom'
import Slider from '../components/Slider'
import { motion } from 'framer-motion'
import { useQuery, gql } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getImages } from '../auth/userSlice'
import Footer from '../components/Footer'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { trackEvent } from '../utils/GoogleAnalytics'
import { Dialog, Transition } from '@headlessui/react'
import { HiXMark } from 'react-icons/hi2'
import axios from 'axios'

const GET_LAST_GAME = gql`
  query getLastGame {
    last_game: game(
      limit: 1
      order_by: { closed_at: desc }
      where: {
        is_active: { _eq: false }
        closed_at: { _is_null: false }
        results: { _neq: [] }
      }
    ) {
      id
      results
      schedule_start
      schedule_end
      product {
        id
        name
        image
      }
    }
  }
`
const GET_TODAY_GAME = gql`
  query gameOfTheDay($schedule_day: jsonb) {
    game(
      limit: 1
      order_by: [{ schedule_start: asc }, { run_end: asc }]
      where: {
        is_active: { _eq: true }
        schedule_start: { _lte: now }
        schedule_end: { _gte: now }
        product: { schedule_day: { _contains: $schedule_day } }
      }
    ) {
      id
      results
      schedule_start
      schedule_end
      run_start
      run_end
      event_code
      claim_expiry_hours
      is_active
      product {
        id
        name
        image
        description
        slogan
        min_stake_amount
        max_stake_amount
        schedule_start
        schedule_end
        schedule_day
        schedule_type
        is_active
        properties
      }
    }
  }
`
let d = moment().weekday()
let todayDate =
  d === 2
    ? 'tue'
    : d === 3
    ? 'wed'
    : d === 4
    ? 'thu'
    : d === 5
    ? 'fri'
    : d === 6
    ? 'sat'
    : d === 0
    ? 'sun'
    : 'mon'

const Home = () => {
  const { data } = useQuery(GET_LAST_GAME)
  const [show, setShow] = useState(true)
  const [gameofDay, setGameofDay] = useState(null)
  const [lastGame, setLastGame] = useState(null)
  const navigate = useNavigate()
  const { data: today } = useQuery(GET_TODAY_GAME, {
    variables: {
      schedule_day: [todayDate]
    }
  })
  const cl = () => {
    // toast.error(`Kindly Log In to play`);
    navigate('/login')
    trackEvent({
      category: 'Button',
      action: 'Click',
      label: 'Play Now Button in todays game section of Home screen',
      value: 1
    })
  }

  const dispatch = useDispatch()
  // const { images } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(getImages())
  }, [dispatch])

  // const gameOfdayImage = images.find(
  //   (element) => element.file_name === gameofDay?.product.image
  // );

  const getGameOfDay = async () =>{
    try{
      let response = await axios.get("https://backend.megafortunelottery.com/api/v1/game/today")
      console.log(response)
      if(response.status === 200 && response?.data?.success === true){
        localStorage.setItem("gameData", JSON.stringify(response?.data?.data[0]));
        setGameofDay(response?.data?.data[0])
      }
      else{
        throw "Something went wrong"
      }
    }
    catch(error){
      throw error
    }
  }

  const getLastDraw = async () =>{
    try{
      let response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/game/last/results`)
      console.log(response)
      if(response.status === 200 && response?.data?.success === true){
        console.log(response?.data?.data?.game[0])
       setLastGame(response?.data?.data?.game[0])
      }
      else{
        throw "Something went wrong"
      }
    }
    catch(error){
      throw error
    }
  }

  useEffect(()=>{
    getGameOfDay()
    getLastDraw()
  },[])

  return (
    <motion.div
      className='div bg-gray-50'
      initial={{ opacity: 0, x: '100%', scale: 1 }}
      animate={{ opacity: 1, x: '0' }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      exit={{ opacity: 0, y: '50%', transition: { duration: 0.1 } }}
    >
      <div>
        <Slider />

        <div>
          <div className='py-32 relative lg:flex lg:justify-center '>
            <div className='flex lg:flex-row text-center justify-center flex-col items-center '>
              <div className='mx-2'>
                <p className=' text-xl font-bold font-tommy capitalize text-gray-600'>
                  Today's Game
                </p>
                <div className=''>
                  {gameofDay ? (
                    <div className=' text-transparent md:w-[600px] w-[350px] bg-gradient-to-r from-[#FF4100] to-[#FFAC00] flex items-center justify-center h-[280px] py-8 px-6 rounded-3xl'>
                      <>
                        <div className=' relative flex-col md:flex-row  md:justify-between justify-center items-center  flex'>
                          <div className=' flex items-center  justify-center bg-white mr-6 p-6 rounded-full border-8 h-32 w-32 md:h-44 md:w-52 '>
                            <img
                              className=' object-contain md:h-[120px]'
                              src={
                                gameofDay?.product.image ===
                                'c32f09c4b7014b1eb81158aeb9f08623.jpg'
                                  ? sokoo
                                  : gameofDay?.product.image ===
                                    '11ebd7ea44474a00b92f6a715fd82d75.jpg'
                                  ? dromo
                                  : gameofDay?.product.image ===
                                    '02782d2986724068955e83d424d576fc.jpg'
                                  ? twadi
                                  : gameofDay?.product.image ===
                                    'e036100c40f548a4b09b120cc17f5ea1.jpg'
                                  ? happy
                                  : gameofDay?.product.image ===
                                    '13c38e6ee09e44759ccc41272dc14a3e.jpg'
                                  ? bingo
                                  : gameofDay?.product.image ===
                                    '21c342244576481f8eaf2805efd0f478.jpg'
                                  ? flow
                                  : gameofDay?.product.image ===
                                    'af5fdac5eda944b5aafd8429e1648fd5.jpg'
                                  ? cashout
                                  : ''
                              }
                              alt='Game of the Day'
                            />
                          </div>
                          <div className='text-white flex flex-col justify-center items-center'>
                            <h3 className='text-xl md:text-3xl font-bold font-tommy uppercase'>
                              Game of the day
                            </h3>
                            {/* <p>Draws in </p> */}

                            <button
                              onClick={cl}
                              className='bg-[#034378] text-white py-3 px-6 mt-4  font-bold font-tommy text-sm h-18  uppercase rounded-3xl '
                            >
                              play now
                            </button>
                          </div>
                        </div>
                      </>
                    </div>
                  ) : (
                    <div className='text-white bg-gradient-to-r text-center from-[#FF4100] to-[#FFAC00] flex h-[280px] rounded-3xl w-full justify-center items-center font-tommy font-bold  text-2xl  '>
                      Sorry there is no game available at the moment
                    </div>
                  )}
                </div>
              </div>

              <div className=' text-gray-600 mx-2'>
                <p className=' text-xl font-bold font-tommy  capitalize mb-3'>
                  Last Draw
                </p>

                <div className=' text-white bg-[#044f87] md:w-[600px] w-[350px] py-8 md:px-6 px-2 rounded-3xl'>
                  <p className='uppercase text-center'></p>

                  <div className=' relative w-full -mt-8 flex items-center justify-center'>
                    <img
                      className='h-64'
                      src={bg}
                      alt='Previous Game results Background'
                    />
                    <div className='absolute inset-0 pt-14 '>
                      <div className=' flex md:gap-3 gap-2 justify-center'>
                        {lastGame ? (
                          <>
                            {lastGame?.results.map((item, key) => (
                              <div
                                key={key}
                                className=' flex items-center justify-center md:h-12 md:w-12 w-9 h-9 rounded-full bg-gradient-to-r from-[#f36b15]  to-[#f5a618]'
                              >
                                <p className=' text-xl'>{item}</p>
                              </div>
                            ))}
                          </>
                        ) : (
                          <>No results</>
                        )}
                      </div>
                      <div className='flex flex-col items-center gap-y-2 mt-5'>
                        <div className='text-center'>
                          <p className=' uppercase'>
                            SIGN UP NOW AND STAND A <br /> CHANCE TO WIN IN THE
                            NEXT DRAW!
                          </p>
                        </div>
                          <button
                            onClick={e => {
                              e.preventDefault()
                              navigate('/sign-up')
                              trackEvent({
                                category: 'Link',
                                action: 'Click',
                                label: 'Sign Up button on home screen',
                                value: 2
                              })
                            }}
                            className='bg-gradient-to-r from-[#f36b15]  to-[#f5a618] py-3 px-6 font-bold font-tommy text-sm h-18  uppercase rounded-3xl '
                          >
                            Sign Up
                          </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className='w-full hidden  relative bg-cover h-[330px] bg-no-repeat text-center md:flex justify-center items-center -bg-[left_5rem]'
            style={{ backgroundImage: `url(${banner})` }}
          >
            <div className='absolute w-full md:w-auto md:flex items-center gap-7'>
              <p className=' text-white  md:text-6xl font-tommy text-4xl font-bold uppercase'>
                A Game for every day!
              </p>
              <button
                onClick={e => {
                  e.preventDefault()
                  navigate('/login')
                  trackEvent({
                    category: 'Button',
                    action: 'Click',
                    label: 'Play now button on Banner web',
                    value: 3
                  })
                }}
                className=' uppercase bg-white py-3 px-6 font-bold font-tommy text-md h-18 mt-3 md:mt-0 rounded-3xl '
              >
                Play Now
              </button>
            </div>
            {/* <img className=" w-full h-[250px] " src={banner} alt="" /> */}
          </div>
          <div
            className='w-full md:hidden  relative bg-cover h-[330px] bg-no-repeat text-center flex justify-center items-center -bg-[left_5rem]'
            style={{ backgroundImage: `url(${banner2})` }}
          >
            <div className='absolute w-full md:w-auto md:flex items-center gap-7'>
              <p className=' text-white  md:text-6xl font-tommy text-4xl font-bold uppercase'>
                A Game for every day!
              </p>
              <button
                onClick={e => {
                  e.preventDefault()
                  navigate('/login')
                  trackEvent({
                    category: 'Button',
                    action: 'Click',
                    label: 'Play now button on Banner mobile',
                    value: 4
                  })
                }}
                className=' uppercase bg-white py-3 px-6 font-bold font-tommy text-md h-18 mt-3 md:mt-0 rounded-3xl '
              >
                Play Now
              </button>
            </div>
            {/* <img className=" w-full h-[250px] " src={banner} alt="" /> */}
          </div>
        </div>
        <div className=''>
          <div className=' py-24'>
            <div className='w-9/12 mx-auto'>
              <p className=' text-xl font-bold font-tommy capitalize mb-3 text-gray-600'>
                {/* Today's Game */}
              </p>
              <div className='lg:flex hidden overflow-auto gap-16 items-center pt-12 justify-center'>
                {[twadi, bingo, happy, flow, cashout, sokoo, dromo].map(
                  (img, key) => (
                    <div>
                      <img
                        key={key}
                        className='h-16'
                        src={img}
                        alt='A game for everyday'
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          <div>
            <div className='md:flex w-8/12 mx-auto -mt-4'>
              <div className=' md:w-1/2 pt-8'>
                <p className='uppercase font-extrabold font-tommy text-6xl py-4'>
                  The orange <br /> box
                </p>
                <p>
                  No phone? No data? You can visit any of our kiosks around the
                  country to play and win!
                </p>
                <button className=' bg-[#f36b15] text-white py-2 px-10 my-6 rounded-3xl uppercase md:text-xl font-bold font-tommy'>
                  COMING SOON
                </button>
              </div>
              <div className='md:w-1/2 w-full'>
                <div className='9/12 mx-auto  h-full rounded-[60px]'>
                  <img className=' ' src={kiosk} alt='Orange Kiosk' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className='bg-gradient-to-r from-[#FF4100]  to-[#FFAC00]'>
            <div className=' md:flex justify-between items-center w-9/12 mx-auto'>
              <div className=' pt-12 text-3xl text-center md:text-left text-white uppercase'></div>
              <div className='flex flex-col py-12 text-center '>
                <p className='text-3xl text-white uppercase font-bold font-tommy'>
                  dial
                </p>
                <p className=' text-[#ffb100]  font-bold font-tommy text-6xl drop-shadow-2xl '>
                  *824#
                </p>
                <p className=' uppercase text-white font-bold font-tommy'>
                  download app
                </p>
                <div className=' flex gap-2 justify-center mt-2'>
                  <img src={android} alt='Google Play Store' />
                  <img src={apple} alt='Apple App Store' />
                </div>
              </div>
              <div>
                <img
                  className='w-56'
                  src={phone}
                  alt='Mega Fortune Lottery Mobile App'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='py-24'>
          <div className=' text-center'>
            <p className='uppercase text-6xl font-tommy font-extrabold'>
              KEEP PLAYING, KEEP WINNING
            </p>
            {/* <p className="pt-2 px-8">
              Win bonus points with every game to keep playing at no extra cost.
            </p> */}
          </div>
        </div>
        {/* footer  */}
        <Footer />
      </div>
      {/* <Announcement show={show} setShow={setShow} /> */}
    </motion.div>
  )
}

// const Announcement = ({ show, setShow }) => {
//   const closeModal = () => {
//     setShow(false)
//   }

//   return (
//     <>
//       <Transition appear show={show} as={Fragment}>
//         <Dialog as='div' className='relative z-10' onClose={closeModal}>
//           <Transition.Child
//             as={Fragment}
//             enter='ease-out duration-300'
//             enterFrom='opacity-0'
//             enterTo='opacity-100'
//             leave='ease-in duration-200'
//             leaveFrom='opacity-100'
//             leaveTo='opacity-0'
//           >
//             <div className='fixed inset-0 bg-black bg-opacity-75' />
//           </Transition.Child>

//           <div className='fixed inset-0 overflow-y-auto'>
//             <div className='flex min-h-full items-center justify-center p-4 text-center'>
//               <Transition.Child
//                 as={Fragment}
//                 enter='ease-out duration-300'
//                 enterFrom='opacity-0 scale-95'
//                 enterTo='opacity-100 scale-100'
//                 leave='ease-in duration-200'
//                 leaveFrom='opacity-100 scale-100'
//                 leaveTo='opacity-0 scale-95'
//               >
//                 <Dialog.Panel className='w-full max-w-2xl transform overflow-hidden mt-24 lg:mt-0 rounded-2xl bg-white text-left align-middle shadow-xl transition-all border-2 border-black'>
//                   <Dialog.Title
//                     as='h3'
//                     className='md:text-[2rem] text-[1.4rem] font-bold font-tommy leading-6 text-[#000000] flex flex-row justify-center items-center pt-5 px-5 md:px-0'
//                   >
//                     <span className='text-center'>
//                       SUNDAY DRAW CANCELLATION
//                     </span>
//                     <HiXMark onClick={(e) => {
//                       e.preventDefault();
//                       closeModal();
//                     }} className='ml-10 cursor-pointer' />
//                   </Dialog.Title>
//                   <div className='mt-2'>
//                     <div className='py-6 bg-white flex flex-col gap-8 shadow-lg '>
//                       <p className='px-16 text-base font-normal leading-[150%]'>
//                         We regret to inform you that due to unforeseen technical
//                         difficulties, the draw scheduled for Sunday, 30th July
//                         2023 (Dromo Event #015) could not be held.
//                       </p>
//                       <p className='px-16 text-base font-normal leading-[150%]'>
//                         Our team is working actively to resolve the issue as
//                         soon as possible and all stakes will be refunded within
//                         the next 24 hours.
//                       </p>
//                       <p className='px-16 text-base font-normal leading-[150%]'>
//                         We apologise any inconvenience caused and appreciate
//                         your understanding and patience during this time.
//                       </p>
//                       <p className='flex flex-col pl-16 font-medium justify-start'>
//                         <span>Signed,</span>
//                         <span>Management</span>
//                       </p>
//                     </div>
//                   </div>
//                 </Dialog.Panel>
//               </Transition.Child>
//             </div>
//           </div>
//         </Dialog>
//       </Transition>
//     </>
//   )
// }

export default Home
