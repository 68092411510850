import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FiX } from 'react-icons/fi'
import MyPagination from './MyPagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper'
import './styles.css'


const AmountModal = ({
  isOpen,
  setIsOpen,
  gameImage,
  selectedNumbers,
  amount,
  setAmount,
  submitMethod,
  gameName
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const numbersPerPage = 12;

  // const nextSlide = () => {
  //   swiper.slideNext()
  // }

  let pages = []
  let pagesContent = []

  for (
    let i = 1;
    i <= Math.ceil(selectedNumbers.length / numbersPerPage);
    i++
  ) {
    pages.push(i)
  }

  // console.log(pages.length)
  // console.log(selectedNumbers.length)

  for (let i = 1; i < pages.length + 1; i++) {
    let lastNumberIndex = i * numbersPerPage
    let firstNumberIndex = lastNumberIndex - numbersPerPage
    let arr = selectedNumbers.slice(firstNumberIndex, lastNumberIndex)
    pagesContent.push(arr)
  }

  // console.log(pagesContent);

  // const pagination = {
  //   clickable: true,
  //   renderBullet: function (index, className) {
  //     return '<span class="' + className + '">' + (index + 1) + '</span>'
  //   }
  // }

  const closeModal = () => {
    setIsOpen(false)
  }

  const onChange = e => {
    setAmount(e.target.value)
    if (e.target.value < 0) {
      setAmount('')
    } else {
      setAmount(e.target.value)
    }
  }

  // const lastNumberIndex = currentPage * numbersPerPage
  // const firstNumberIndex = lastNumberIndex - numbersPerPage
  // const currentNumbers = selectedNumbers.slice(
  //   firstNumberIndex,
  //   lastNumberIndex
  // )

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10 ' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-75 ' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-hidden '>
            <div className='flex min-h-full items-center justify-center p-4 text-center '>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-screen max-w-2xl h-screen transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900 flex flex-col p-3'
                  >
                    <div>
                      <FiX
                        onClick={e => {
                          e.preventDefault()
                          closeModal()
                        }}
                      />
                    </div>
                    <div className='flex flex-col items-center justify-center'>
                      <div className='flex-shrink-0 bg-white w-36 mx-auto p-6 rounded-full shadow-lg border-4 border-[#043060] mb-2  items-center justify-center '>
                        <img className='h-20' src={gameImage} alt='Game of the Day' />
                      </div>
                      <div className='lg:px-0 px-6 text-center text-[#043060] font-bold'>
                        <h2>How much do you want to play?</h2>
                      </div>
                    </div>
                  </Dialog.Title>
                  <div className='px-6 '>
                    <div>
                      <div className='border-2 border-b-0 border-[#FF7300] bg-[#FFF1E6] text-[#662E00] font-bold text-base uppercase rounded-tl-lg rounded-tr-lg px-2 py-1'>
                        {gameName}
                      </div>
                      <div className='max-h-[140px] h-[140px] overflow-y-auto border-2 border-[#FF7300] flex flex-col  px-2 py-2 '>
                        <Swiper
                          pagination={true}
                          modules={[Pagination]}
                          // onSwiper={s => {
                          //   console.log('initialize swiper', s)
                          //   setSwiper(s)
                          // }}
                          onSlideChange={(swiper) => {
                            // console.log('slide change')
                            // console.log("Active Index",swiper.activeIndex)
                            // console.log("Swiper direction",swiper.activeIndex)
                            setCurrentPage(swiper.realIndex+1)

                          }
                        }
                          className=''
                        >
                          {pagesContent.map((page, key) => {
                            return (
                              <SwiperSlide
                                className='grid grid-cols-6 w-[328px] gap-0 pt-3'
                                key={key}
                              >
                                {page.map((item, key) => (
                                  <p
                                    key={key}
                                    className=' flex items-center justify-center text-white font-tommy bg-gradient-to-b from-[#FFAC00] to-[#FF4100] w-8 h-8 rounded-full'
                                  >
                                    {item}
                                  </p>
                                ))}
                              </SwiperSlide>
                            )
                          })}
                        </Swiper>
                      </div>
                      <div className='flex flex-row justify-between px-2 py-2 items-center  border-2 border-[#FF7300] rounded-br-lg rounded-bl-lg border-t-0'>
                        <div className='text-[#994500] text-xs'>
                          Swipe to see more
                        </div>
                        <MyPagination
                          totalNumber={selectedNumbers.length}
                          numbersPerPage={numbersPerPage}
                          setCurrentPage={setCurrentPage}
                          currentPage={currentPage}
                          // nextSlide={nextSlide}
                        />
                      </div>
                    </div>
                    <div className='my-8 text-xl flex items-center mb-9'>
                      GHS
                      <input
                        className=' border-b-2 text-4xl text-center text-black  w-full px-6'
                        type='number'
                        name='amount'
                        onChange={onChange}
                        value={amount}
                        min='0'
                      />
                    </div>
                    <div className=' flex justify-center mb-6 '>
                      <button
                        onClick={e => {
                          e.preventDefault()
                          submitMethod()
                        }}
                        // type='submit'
                        // disabled={!isValid || pending}
                        className='hover:bg-[#FF4100] py-2 transition-all hover:text-white  bg-[#FF7300] text-white lg:px-8 px-4 rounded-3xl font-bold mx-3'
                      >
                        Confirm and Pay
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default AmountModal
