import React from "react";
// import { useAuth0 } from "@auth0/auth0-react";
// import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'

const SignUpButton = ({setOpen}) => {
  const navigate = useNavigate()
  // const { isAuthenticated } = useAuth0();
  return (
    !localStorage.getItem("token") && (
        <button onClick={(e) => {
          e.preventDefault();
          navigate('/sign-up')
          setOpen(false)
        }} className="hover:bg-[#FF7300] uppercase font-tommy hover:text-white md:ml-2 bg-white text-[#FF7300] border border-[#FF7300] transition-all py-1 px-4 rounded-3xl">
          Sign Up
        </button>
    )
  );
};

export default SignUpButton;
