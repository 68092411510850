import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export const getGameDetails = createAsyncThunk(
  "game/details",
  async (game_id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/storage/games/${game_id}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRegions = createAsyncThunk("location/regions", async () => {
  const res = await axiosInstance.get(`/location/regions`);
  return res.data;
});

export const stakePayment = createAsyncThunk(
  "stake/payment",
  async (payment, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        " Authorization": `Bearer ` + token,
        "Content-Type": "application/json",
      };
      const res = await axiosInstance.post("/stake/payment/init", payment, {
        headers,
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  games: localStorage.getItem("gameItems")
    ? JSON.parse(localStorage.getItem("gameItems"))
    : [],
  pending: null,
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    addGame(state, { payload }) {
      if (state.games.length < 4) {
        state.games.push(payload);
      }
    },
    removeGame(state, { payload }) {
      const nextGame = state.games.filter((game) => game.id !== payload.id);
      state.games = nextGame;
    },
    removeAllGames(state) {
      state.games = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(stakePayment.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(stakePayment.fulfilled, (state) => {
      state.pending = false;
    });

    builder.addCase(stakePayment.rejected, (state) => {
      state.pending = null;
    });
  },
});

export const { addGame, removeGame, removeAllGames } = gameSlice.actions;
export default gameSlice.reducer;
