import React from 'react'

const PricingTable = ({ title, games }) => {
  return (
    <div className='flex flex-col mb-10'>
      <h1 className='font-tommy text-[2rem] mb-5'>{title}</h1>
      <table className='text-left'>
        <tr className='text-base font-medium border-b-2 border-t-2 border-black'>
          <th className='py-3'>COST TO STAKE</th>
          <th className='py-3'>GAME TYPE</th>
          <th className='py-3'>MULTIPLIER</th>
          <th className='py-3'>WINNING AMOUNT</th>
        </tr>
        {games?.map((game, key) => (
          <tr className='py-16 border-b-2 border-[#E6E5E5]' key={key}>
            <td className='py-3'>{game.stakeCost}</td>
            <td className='py-3'>{game.gameType}</td>
            <td className='py-3'>{game.multiplier}</td>
            <td className='py-3'>{game.winning}</td>
          </tr>
        ))}
      </table>
    </div>
  )
}

export default PricingTable
