import React, { useState, useEffect } from 'react'

function Timer ({ disableGame }) {
  const [timeLeft, setTimeLeft] = useState('')

  useEffect(() => {
    const countDownDate = new Date()

    if (disableGame) {
      countDownDate.setHours(24, 0, 0) // Set the countdown time to 12:00 AM
    } else {
      countDownDate.setHours(19, 0, 0) // Set the countdown time to 7:00 PM
    }

    const timer = setInterval(() => {
      const now = new Date().getTime()
      const distance = countDownDate - now

  
        const hours = Math.floor(distance / (1000 * 60 * 60))
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((distance % (1000 * 60)) / 1000)

        setTimeLeft(
          `${Math.abs(hours).toString().padStart(2, '0')}:${Math.abs(minutes)
            .toString()
            .padStart(2, '0')}:${Math.abs(seconds).toString().padStart(2, '0')}`
        )
    }, 1000)

    return () => clearInterval(timer)
  }, [disableGame])

  return (
    <>
      {disableGame ? (
        <div className='flex flex-row w-full justify-between px-5 xl:px-10 xl:mt-2 text-[12px] md:text-[16px] xl:text-[26px]'>
          <p>Starts in:</p>
          <p className='bg-[#0581CE] px-2 rounded-lg'>{timeLeft}</p>
        </div>
      ) : (
        <div className='flex flex-row w-full justify-between px-5 xl:px-10 xl:mt-2 text-[12px] md:text-[16px] xl:text-[26px]'>
          <p>Closes in:</p>
          <p className='bg-[#0581CE] px-2 rounded-lg'>{timeLeft}</p>
        </div>
      )}
    </>
  )
}

export default Timer
