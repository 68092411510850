import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../src/auth/userSlice";
import gameReducer from "./pages/dashboard/gameSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    game: gameReducer
  },
});
