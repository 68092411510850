import React from 'react'
import facebook from '../assets/images/icons/facebook.png'
import instagram from '../assets/images/icons/instagram.png'
import tiktok from '../assets/images/icons/tiktok-32.png'
import { Youtube } from 'react-feather'
// import linkedIn from "../assets/images/icons/linkedIn.png";
import twitter from '../assets/images/icons/twitter.png'
import nla from '../assets/images/icons/NLA.png'
import logoRound from '../assets/images/logos/Mega Fortune Logo Round.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  const redirect = url => {
    window.location.replace(url)
  }

  return (
    <footer id='footer' className='bg-black py-8 '>
      <div className='w-9/12 mx-auto'>
        <div className='md:flex text-center md:text-left justify-between py-12'>
          <div className='flex justify-center items-center lg:mr-0 mr-10 '>
            <img
              className='h-20 lg:h-40'
              src={logoRound}
              alt='Mega Fortune Logo'
            />
          </div>

          <div className=' mt-8 md:mt-0'>
            <p className=' uppercase mb-2 text-[#f36b15]'>Useful links</p>
            <ul className='text-white text-sm'>
              {/* <Link to="contact">
                <li className=" capitalize">Contact Us</li>
              </Link> */}
              <Link to='about-us'>
                <li className=' capitalize'>About Us</li>
              </Link>
              <Link to='faqs'>
                <li className=' capitalize'>FAQs</li>
              </Link>
              <Link to='terms-conditions'>
                <li className='capitalize'>Terms & Conditions</li>
              </Link>
              <Link to='privacy-policy'>
                <li className='capitalize'>Privacy Policy</li>
              </Link>
              <Link to='support'>
                <li className='capitalize'>Support</li>
              </Link>
            </ul>
          </div>

          <div className='mx-5'>
            <div className='flex items-center justify-center uppercase font-bold font-tommy mb-2 mt-8 md:mt-0 text-gray-200'>
              <div className='flex gap-3 items-center '>
                <a
                  rel='noreferrer'
                  href={
                    'https://web.facebook.com/people/Mega-Fortune-Lottery/100088536172585/?_rdc=1&_rdr'
                  }
                  target='_blank'
                >
                  <img src={facebook} alt='Facebook' />
                </a>
                <a
                  rel='noreferrer'
                  href={'https://www.instagram.com/megafortune696/'}
                  target='_blank'
                >
                  <img src={instagram} alt='Instagram' />
                </a>

                <a
                  rel='noreferrer'
                  href={'https://twitter.com/megafortune696'}
                  target='_blank'
                >
                  <img src={twitter} alt='Twitter' />
                </a>

                <a
                  rel='noreferrer'
                  href={'https://www.tiktok.com/@megafortune696?lang=en'}
                  target='_blank'
                >
                  <img src={tiktok} alt='Tik Tok' />
                </a>

                <a
                 rel='noreferrer'
                 href={'https://www.youtube.com/channel/UC5vecLJPjT-aC8T_IyGrpqQ'}
                  target='_blank'
                >
                  <Youtube className='h-7 w-7 flex-shrink-0 text-white' />
                </a>
              </div>
            </div>
            <ul className='text-white text-sm pt-6'>
              <li className='capitalize'>030 290 9909</li>
              <li className='capitalize'>020 009 6636</li>
              <li className='capitalize'>053 116 8672</li>
              <li className='capitalize'>024 899 9932</li>
              <li className='capitalize'>North Legon</li>
            </ul>
          </div>

          <div className='text-white mt-8 md:mt-0'>
            <div className='mb-2  text-xs flex justify-center items-center'>
              <p>
                Ages <span className='font-bold font-tommy text-xl'>18+</span>
              </p>
              <img className='h-12 py-1 px-2' src={nla} alt='NLA approved' />
              <p>Approved</p>
            </div>
            <div className=' flex flex-col gap-3 text-xs'>
              <p>Lottery is a game of chance</p>
              <p className=' font-bold font-tommy text-xs'>Play Responsibly</p>
            </div>
          </div>
        </div>
      </div>
      <div className=' pt-4 border-t'>
        <div className='flex lg:flex-row flex-col lg:px-60 px-10 lg:justify-between lg:text-start text-center mx-auto'>
          <p className='text-[16px] text-gray-200'>
            Copyright&nbsp;Fortune Synergy <span>2023.</span>
            <span className=' font-bold font-tommy'>
              {' '}
              All&nbsp;rights&nbsp;reserved{' '}
            </span>
          </p>
          <span className='text-xs text-gray-200'>
            Powered By{' '}
            <Link
              className='underline text-[16px] underline-offset-auto'
              onClick={e => {
                e.preventDefault()
                redirect('https://www.omnistrategies.net/')
              }}
            >
              Omni&nbsp;Strategies
            </Link>
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
