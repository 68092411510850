import React, { useState } from 'react'

import bg from '../assets/images/Mega-Wins.jpg'
import dromo from '../assets/images/games/dromo.png'
import bingo from '../assets/images/games/bingo.png'
import happy from '../assets/images/games/mrhappy.png'
import flow from '../assets/images/games/overflow.png'
import sokoo from '../assets/images/games/sokooo.png'
import twadi from '../assets/images/games/twadi.png'
import cashout from '../assets/images/games/CASHOUT.png'
import { motion } from 'framer-motion'
import { useQuery, gql } from '@apollo/client'
import { ArrowDownCircle } from 'react-feather'
// import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// import dayjs from "dayjs";

const GET_GAMES = gql`
  query getProducts {
    product {
      id
      image
      description
      name
      slogan
      schedule_end
      schedule_start
      schedule_day
    }
  }
`
const Games = () => {
  const [productsData, setProductsData] = useState(null)
  const navigate = useNavigate()
  const { loading } = useQuery(GET_GAMES, {
    onCompleted: data => {
      setProductsData(sortDays(data))
    }
  })
  // let productsData = null

  const sortDays = results => {
    let newResults = [...results?.product]
    const sortedData = newResults.sort((a, b) => {
      const dayA = a?.schedule_day[0]
      const dayB = b?.schedule_day[0]
      // Define the order of the days of the week
      const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
      // Compare the position of the days in the daysOfWeek array
      const positionA = daysOfWeek.indexOf(dayA)
      const positionB = daysOfWeek.indexOf(dayB)

      return positionA - positionB
    })

    // console.log(sortedData)

    return sortedData
  }

  // console.log(sortDays(data));

  // useEffect(() => {
  //   if (data) {
  //     // console.log(sortDays(data))
  //     setProductsData(sortDays(data))
  //     // productsData = sortDays(data);
  //   }
  // }, [productsData])

  const cl = () => {
    // toast.error(`Kindly Log In to play`)
    navigate('/sign-up')
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='w-full bg-gray-50'
    >
      {process.env.REACT_APP_ENVIRONMENT_NAME === 'PRODUCTION' ? (
        <Helmet>
          <title>Games</title>
          <meta
            name='description'
            content='A page for the description of various games available on Mega Fortune Lottery'
          />
        </Helmet>
      ) : null}

      <div
        className='flex justify-center bg-cover bg-center items-center bg-no-repeat py-36 text-white w-full h-screen'
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className='flex flex-col'>
          <p className='font-bold font-tommy text-center uppercase text-6xl'>
            Games
          </p>

          <button
            onClick={cl}
            className=' mx-12 mt-6 uppercase bg-[#FF7300] text-white font-bold font-tommy py-2 px-8 rounded-3xl'
          >
            Play Now
          </button>
        </div>
        <div className=' absolute bottom-0 pb-4 animate-bounce'>
          <ArrowDownCircle color='white' size={35} />
        </div>
      </div>
      <div className='py-32'>
        <div className='w-10/12 mx-auto justify-center grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-16'>
          {loading ? <div>loading</div> : ''}
          {productsData
            ? productsData?.map((item, key) => (
                <div
                  key={key}
                  className='flex flex-col overflow-hidden rounded-lg hover:shadow-xl transition-all shadow-lg'
                >
                  <div className='flex-shrink-0'>
                    <img
                      className='h-48 w-full p-4 object-contain'
                      src={
                        item.image === 'c32f09c4b7014b1eb81158aeb9f08623.jpg'
                          ? sokoo
                          : item.image ===
                            '11ebd7ea44474a00b92f6a715fd82d75.jpg'
                          ? dromo
                          : item.image ===
                            '02782d2986724068955e83d424d576fc.jpg'
                          ? twadi
                          : item.image ===
                            'e036100c40f548a4b09b120cc17f5ea1.jpg'
                          ? happy
                          : item.image ===
                            '13c38e6ee09e44759ccc41272dc14a3e.jpg'
                          ? bingo
                          : item.image ===
                            '21c342244576481f8eaf2805efd0f478.jpg'
                          ? flow
                          : item.image ===
                            'af5fdac5eda944b5aafd8429e1648fd5.jpg'
                          ? cashout
                          : ''
                      }
                      alt='Game'
                    />
                  </div>
                  <div className='flex flex-1 flex-col justify-between bg-white p-6'>
                    <div className='flex-1'>
                      <p className='text-md font-bold font-tommy '>
                        <a href className='hover:underline'>
                          {item.slogan}
                        </a>
                      </p>
                      <a href className='mt-2 block'>
                        <p className='text-xl font-semibold font-tommy text-gray-900'>
                          {item.name}
                        </p>
                        <div
                          className='mt-3 text-base  text-gray-500'
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </a>
                    </div>
                    <div className='mt-6 flex items-center'>
                      <div className='ml-3'>
                        <p className='text-sm font-medium text-gray-900'>
                          <p
                            // href={item.author.href}
                            className='hover:underline'
                          >
                            {/* {item.slogan} */}
                          </p>
                        </p>

                        <span className=' text-sm mr-2 font-semibold font-tommy text-gray-500'>
                          Every
                        </span>
                        <span className='inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium mt-3 text-blue-800'>
                          {item.schedule_day[0] === 'mon' ? (
                            <p>Monday</p>
                          ) : item.schedule_day[0] === 'tue' ? (
                            <p>Tuesday</p>
                          ) : item.schedule_day[0] === 'wed' ? (
                            <p>Wednesday</p>
                          ) : item.schedule_day[0] === 'thu' ? (
                            <p>Thursday</p>
                          ) : item.schedule_day[0] === 'fri' ? (
                            <p>Friday</p>
                          ) : item.schedule_day[0] === 'sat' ? (
                            <p>Saturday</p>
                          ) : item.schedule_day[0] === 'sun' ? (
                            <p>Sunday</p>
                          ) : (
                            ''
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </motion.div>
  )
}

export default Games
