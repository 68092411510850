import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { getRegions } from "./gameSlice";
import { getUserDetails } from "../../auth/userSlice";

const UPDATE_USER_DETAILS = gql`
  mutation updateUserDetails(
    $id: uuid = ""
    $first_name: String = ""
    $ghana_card: String = ""
    $middle_name: String = ""
    $last_name: String = ""
    $national_id: String = ""
    $digital_address: String = ""
    $address: String = ""
    $phone_number: bigint = ""
    $region_id: uuid = ""
    $town_city: String = ""
    $selfie: String = ""
  ) {
    update_customer_by_pk(
      pk_columns: { id: $id }
      _set: {
        first_name: $first_name
        ghana_card: $ghana_card
        middle_name: $middle_name
        last_name: $last_name
        national_id: $national_id
        digital_address: $digital_address
        address: $address
        phone_number: $phone_number
        region_id: $region_id
        town_city: $town_city
        selfie: $selfie
      }
    ) {
      id
      first_name
      ghana_card
      middle_name
      last_name
      national_id
      digital_address
      address
      phone_number
      region_id
      town_city
      selfie
    }
  }
`;

// const networks = [
//   {
//     id: 0,
//     name: "Select Momo Nekwork",
//     value: "",
//   },
//   {
//     id: 1,
//     name: "Mtn",
//     value: "mtn-gh",
//   },
//   {
//     id: 2,
//     name: "Vodafone",
//     value: "vodafone-gh",
//   },
//   {
//     id: 3,
//     name: "AirtelTigo",
//     value: "tigo-gh",
//   },
// ];
const Account = () => {
  
  const dispatch = useDispatch();
  useEffect(() => {
    getRegion();
  }, []);
  const [updateUserDetails] = useMutation(UPDATE_USER_DETAILS);
  const [firstName, setFirstName] = useState("");
  useEffect(() => {
    getUserInfo();
  }, []);

  const [LastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nationalId, setId] = useState("");
  const [ghanaCard, setGhanaCard] = useState("");
  const [digitalAddress, setDigitalAddress] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  // const [email, setEmail] = useState("");
  const [selfie, setSelfie] = useState("");
  const [r, setR] = useState("");

  // const { user } = useAuth0();
  const userDetails = JSON.parse(localStorage.getItem("user"));

  // console.log(userDetails)

  const getUserInfo = async () => {
    setFirstName(userDetails?.data.first_name);
    setMiddleName(userDetails?.data.middle_name);
    setLastName(userDetails?.data.last_name);
    setPhoneNumber(userDetails?.data.phone_number);
    setCity(userDetails?.data.town_city);
    setRegion(userDetails?.data.region_id);
    setDigitalAddress(userDetails?.data.digital_address);
    setId(userDetails?.data.national_id);
    // setEmail(userDetails.data.email);
    setSelfie(userDetails?.data.selfie);
    setAddress(userDetails?.data.address);
    setGhanaCard(userDetails?.data.ghana_card);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUserDetails({
      variables: {
        id: details.data.id,
        first_name: firstName,
        middle_name: middleName,
        last_name: LastName,
        phone_number: phoneNumber,
        // ghana_card: ghanaCard,
        // national_id: nationalId,
        digital_address: digitalAddress,
        address: address,
        town_city: city,
        region_id: region,
        // email: email,
        // selfie: selfie,
      },
    });
    dispatch(getUserDetails());
    toast.success("User details updated successufully");
  };
  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setGhanaCard(base64);
  };
  const uploadSelfie = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setSelfie(base64);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const getRegion = async () => {
    const res = await dispatch(getRegions());
    setR(res.payload.data.region);
  };

  const handleChange = (e) => {
    setRegion(e.target.value);
  };
  const details = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      {userDetails ? (
        <div>
          {/* <div>
            {details?.data.status === "kyc_required" ? (
              <div className="relative bg-red-100 isolate w-full flex items-center gap-x-6 mb-6 overflow-hidden py-2.5 px-6 sm:px-3.5 sm:before:flex-1">
                <div className="flex  flex-wrap items-center gap-y-2 gap-x-4">
                  <p className=" leading-6 text-red-500">
                    <strong className="font-semibold">Details Required</strong>
                    <svg
                      viewBox="0 0 2 2"
                      className="mx-2 inline h-0.5 w-0.5 fill-current"
                      aria-hidden="true"
                    >
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    Kindly complete your registration to stake
                  </p>
                </div>
                <div className="flex flex-1 justify-end">
                  <button
                    type="button"
                    className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                  >
                    <span className="sr-only">Dismiss</span>
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div> */}
          <>
            <div className="mt-10 sm:mt-0 h-screen">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Personal Information
                    </h3>
                    <div className="flex h-full py-12 justify-center items-center">
                      <div className="flex gap-1 flex-col justify-center items-center bg-slate-600 text-white px-8 py-8 rounded-full ">
                        {/* <img
                          className=" rounded-full my-9"
                          src={user.picture}
                          alt=""
                        /> */}
                        <p className=" text-2xl">
                          {userDetails?.data.first_name +
                            " " +
                            userDetails?.data.last_name}
                        </p>
                        <p className="text-xl">{userDetails?.data.email}</p>
                        {/* <p>
                          Account:{" "}
                          {user.email_verified ? (
                            <span className="  bg-green-200 uppercase py-1 px-6 rounded-md">
                              Verified
                            </span>
                          ) : (
                            <span className=" bg-red-200 uppercase  py-1 px-6 rounded-md">
                              Unverified
                            </span>
                          )}
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0 lg:mb-0 mb-5">
                  <form onSubmit={handleSubmit}>
                    <div className="overflow-hidden shadow-lg border-2 sm:rounded-md">
                      <div className="bg-white px-4 py-5 sm:p-6">
                        <div className="flex gap-10 bg-white  py-5 sm:p-6">
                          <div className="w-1/2">
                            {/* <label className="block  font-medium text-gray-700">
                              Ghana Card
                            </label>
                            <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                              {ghanaCard ? (
                                <div>
                                  <img src={ghanaCard} alt="" />
                                  <div className="space-y-1 mt-6 text-center">
                                    <div className="flex  text-gray-600">
                                      <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                      >
                                        <span>Change Image</span>
                                        <input
                                          id="file-upload"
                                          name="file-upload"
                                          type="file"
                                          onChange={(e) => {
                                            uploadImage(e);
                                          }}
                                          className="sr-only"
                                        />
                                      </label>
                                    </div>
                                    <p className="text-xs text-gray-500">
                                      PNG, JPG, GIF up to 10MB
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div className="space-y-1 text-center">
                                  <svg
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <div className="flex  text-gray-600">
                                    <label
                                      htmlFor="file-upload"
                                      className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                    >
                                      <span>Upload a file</span>
                                      <input
                                        id="file-upload"
                                        name="file-upload"
                                        type="file"
                                        onChange={(e) => {
                                          uploadImage(e);
                                        }}
                                        className="sr-only"
                                      />
                                    </label>
                                  </div>
                                  <p className="text-xs text-gray-500">
                                    PNG, JPG, GIF up to 10MB
                                  </p>
                                </div>
                              )}
                            </div> */}
                          </div>
                          {/* <div className="w-1/2 relative -left-52">
                            <label className="block  font-medium text-gray-700">
                              Selfie
                            </label>
                            <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                              {selfie ? (
                                <div>
                                  <img src={selfie} alt="" />
                                  <div className="space-y-1 mt-6 text-center">
                                    <div className="flex  text-gray-600">
                                      <label
                                        htmlFor="file-upload2"
                                        className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                      >
                                        <span>Change Image</span>
                                        <input
                                          id="file-upload2"
                                          name="file-upload2"
                                          type="file"
                                          onChange={(e) => {
                                            uploadSelfie(e);
                                          }}
                                          className="sr-only"
                                        />
                                      </label>
                                    </div>
                                    <p className="text-xs text-gray-500">
                                      PNG, JPG, GIF up to 10MB
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div className="space-y-1 text-center">
                                  <svg
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <div className="flex  text-gray-600">
                                    <label
                                      htmlFor="file-upload2"
                                      className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                    >
                                      <span>Upload file</span>
                                      <input
                                        id="file-upload2"
                                        name="file-upload2"
                                        type="file"
                                        onChange={(e) => {
                                          uploadSelfie(e);
                                        }}
                                        className="sr-only"
                                      />
                                    </label>
                                  </div>
                                  <p className="text-xs text-gray-500">
                                    PNG, JPG, GIF up to 10MB
                                  </p>
                                </div>
                              )}
                            </div>
                          </div> */}
                        </div>
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="first-name"
                              className="block  font-medium text-gray-700"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="first-name"
                              className="block  font-medium text-gray-700"
                            >
                              Middle Name
                            </label>
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              value={middleName}
                              onChange={(e) => setMiddleName(e.target.value)}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block  font-medium text-gray-700"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              value={LastName}
                              onChange={(e) => setLastName(e.target.value)}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:"
                            />
                          </div>
                          {/* <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last-name"
                              className="block  font-medium text-gray-700"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              autoComplete="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:"
                            />
                          </div> */}
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="email-address"
                              className="block  font-medium text-gray-700"
                            >
                              Phone Number (+233)
                            </label>
                            <input
                              type="text"
                              name="phone-number"
                              id="phone-number"
                              autoComplete="phone"
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:"
                            />
                          </div>

                          {/* <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="email-address"
                              className="block  font-medium text-gray-700"
                            >
                              National ID
                            </label>
                            <input
                              type="text"
                              name="national-id"
                              id="national-id"
                              autoComplete="email"
                              value={nationalId}
                              onChange={(e) => setId(e.target.value)}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:"
                            />
                          </div> */}

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="email-address"
                              className="block  font-medium text-gray-700"
                            >
                              Digital Address
                            </label>
                            <input
                              type="text"
                              name="email-address"
                              id="email-address"
                              autoComplete="email"
                              value={digitalAddress}
                              onChange={(e) =>
                                setDigitalAddress(e.target.value)
                              }
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="email-address"
                              className="block  font-medium text-gray-700"
                            >
                              Address
                            </label>
                            <input
                              type="text"
                              name="address"
                              id="address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="email-address"
                              className="block  font-medium text-gray-700"
                            >
                              City/Town
                            </label>
                            <input
                              type="text"
                              name="email-address"
                              id="email-address"
                              autoComplete="email"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            {r ? (
                              <>
                                {" "}
                                <label
                                  htmlFor="email-address"
                                  className="block  font-medium text-gray-700"
                                >
                                  Region
                                </label>
                                <select
                                  onChange={handleChange}
                                  name="Regions"
                                  id="cars"
                                  value={region}
                                >
                                  {r.map((item, key) => (
                                    <option key={key} value={item.id} defaultValue="selected">
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </>
                            ) : (
                              ""
                            )}
                          </div>

                          {/* <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="country"
                              className="block  font-medium text-gray-700"
                            >
                              Momo Network
                            </label>
                            <select
                              id="country"
                              name="country"
                              autoComplete="country-name"
                              value={selected}
                              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:"
                            >
                              <option>Mtn</option>
                              <option>Vodafone</option>
                              <option>Airtel-Tigo</option>
                            </select>
                          </div> */}
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 mb-3 text-right sm:px-6">
                        {/* <button
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4  font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Save
                        </button> */}

                        <button
                          type="submit"
                          className=" uppercase w-28 font-tommy text-white bg-gradient-to-r from-[#FFAC00] to-[#FF4100] rounded-3xl py-2 px-4 "
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Account;
