import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const MEASUREMENT_ID = 'G-7KL8HWBRSQ'; // Replace with your Google Analytics Measurement ID

export const initializeAnalytics = () => {
  ReactGA.initialize(MEASUREMENT_ID);
};

export const trackPageView = () => {
  ReactGA.send({ hitType: 'pageview' });
};

export const trackEvent = ({ category, action, label, value }) => {
  ReactGA.send({ hitType: 'event', eventCategory: category, eventAction: action, eventLabel: label, eventValue: value });
};

export const usePageViews = () => {
  useEffect(() => {
    trackPageView();
  }, []);
};
