import { Outlet } from "react-router-dom";

const PrivateRoutes = () => {
  // const navigate = useNavigate();
  // const res = await localStorage.getItem("customer_id")
  const redirect = () => {
    // navigate('/dashboard')
    // // loginWithRedirect();
  };

  return localStorage.getItem("token") ? <Outlet /> : redirect();
};

export default PrivateRoutes;
