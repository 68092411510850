import { Route, Routes } from 'react-router-dom'
import { useEffect } from 'react'
import AgentRegistration from './pages/AgentRegistration'
import Gallery from './pages/Gallery'
import Games from './pages/Games'
import GamesFive from './pages/GamesFive'
import 'react-toastify/dist/ReactToastify.min.css'
import GamesThree from './pages/GamesThree'
import Home from './pages/Home'
import FAQs from './pages/FAQs'
import Privacy from './pages/Privacy'
import Login from './pages/Login'
import HowToPlay from './pages/HowToPlay'
import Layout from './pages/Layout'
import Results from './pages/Results'
import Signin from './pages/Signin'
import PrivateRoutes from './utils/PrivateRoutes'
import AboutUs from './pages/AboutUs'
// import Contact from "./pages/Contact";
import TermsAndConditions from './pages/TermsAndConditions'
import { ToastContainer } from 'react-toastify'
import AddGame from './pages/AddGame'
import Index from './pages/dashboard'
import DashHome from './pages/dashboard/Home'
import Game from './pages/dashboard/Game'
import History from './pages/dashboard/History'
import Account from './pages/dashboard/Account'
import Support from './pages/Support'
import NotFound from './pages/NotFound'
import GameDynamics from './pages/GameDynamics'
import GamesHome from './pages/GamesHome'
import GamesPricing from './pages/GamesPricing'

import { usePageViews, initializeAnalytics } from './utils/GoogleAnalytics'
const contextClass = {
  success: 'bg-green-400 text-white',
  error: 'bg-red-400 text-white text-2xl font-bold',
  info: 'bg-gray-600',
  warning: 'bg-orange-400',
  default: 'bg-indigo-600',
  dark: 'bg-white-600 font-gray-300'
}

function App () {


  useEffect(() => {
    initializeAnalytics();
  }, []);

  usePageViews();

  return (
    <div className='App'>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path='dashboard' element={<Index />}>
              <Route index element={<DashHome />} />
              <Route path='history' element={<History />} />
              <Route path='account' element={<Account />} />
              <Route path='game' element={<Game />}>
                <Route index element={<GamesThree />} />
                <Route path='play/:id' element={<GamesFive />} />
                <Route path='add-game' element={<AddGame />} />
              </Route>
            </Route>
          </Route>

          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='gallery' element={<Gallery />} />
            <Route path='how-to-play' element={<HowToPlay />} />
            <Route path='sign-up' element={<Signin />} />
            <Route path='login' element={<Login />} />
            <Route path='agent-registration' element={<AgentRegistration />} /> 
            <Route path='results' element={<Results />} />
            <Route path='games-home' element={<GamesHome />} />
            <Route path='daily-games' element={<Games />} />
            <Route path='game-dynamics' element={<GameDynamics />} />
            <Route path='game-pricing' element={<GamesPricing />} />
            <Route path='faqs' element={<FAQs />} />
            <Route path='terms-conditions' element={<TermsAndConditions />} />
            <Route path='privacy-policy' element={<Privacy />} />
            <Route path='about-us' element={<AboutUs />} />
            {/* <Route path="contact" element={<Contact />} /> */}
            <Route path='support' element={<Support />} />
            {/* <Route path="404" element={<NotFound />} /> */}
          </Route>
          <Route path='*' element={<NotFound />}></Route>
        </Routes>
      <ToastContainer
        toastClassName={({ type }) =>
          contextClass[type || 'default'] +
          '  relative flex p-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer'
        }
        bodyClassName={() => 'text-sm  font-med  block flex p-2'}
        position='top-right'
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default App
