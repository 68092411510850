import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Provider } from 'react-redux'
import store from './store'


// import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
// import { createClient } from 'graphql-ws';

// const wsLink = new GraphQLWsLink(createClient({
//   url: 'ws://api.staging.megafortunelottery.com/v1/graphql/subscriptions',
// }));

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_URL
})

const authLink = setContext((_, { headers }) => {
  // let token = localStorage.getItem('token')

  // let userRole = token == null ? 'anonymous' : ''
  // let authorization = userRole === 'anonymous' ? '' : `Bearer ${token}`

  // return {
  //   headers: {
  //     ...headers,
  //     Authorization: authorization,
  //     'X-Hasura-Role': userRole
  //   }
  // }

  return {
    headers: {
      ...headers,
      'x-hasura-admin-secret': process.env.REACT_APP_HASURA_SECRET
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {process.env.REACT_APP_ENVIRONMENT_NAME === 'PRODUCTION' ? (
        <Helmet>
          <title>Mega Fortune</title>
          <meta
            name='description'
            content='Mega Fortune is the newest and boldest player in Ghana’s thriving Lottery Industry. 
          Licensed by the National Lottery Authority (NLA), our mission is to bring good fortune, change lives and responsibly 
          provide the thrill of lottery entertainment.  We have a game every day of the week, easily playable on all platforms (Website, Android, and iOS). 
          All Mega Fortune games can be played with a minimum of GHS 1.00. We enable ambitious people to unlock opportunities for big wins with our wide range of games. 
          With our trusted agents and online platforms, you can play Live Lotteries at your convenience. '
          />
          <meta
            name='keywords'
            content='Mega Fortune lottery, Mega Fortune lottery jackpot, Mega Fortune lottery winners, Mega Fortune lottery ticket, Mega Fortune lottery draw, 
          Mega Fortune lotto results, international Mega Fortune lottery, online Mega Fortune lottery, buy Mega Fortune lottery tickets, Mega Fortune lotto game, 
          Mega Fortune lotto bonus, Worldwide Mega Fortune lottery, Mega Fortune lottery game play, Mega Fortune lottery strategy, Android Mega Fortune Lottery app, 
          iOS Mega Fortune Lottery app, mega fortune jackpot, mega fortune winning numbers, mega fortune slot machine, mega fortune payout, play mega fortune, 
          buying mega fortune tickets, european mega fortune lottery, lottery tickets online, mega millions lottery, euro millions lottery, lotto tickets online, 
          win the lottery jackpot, mega millions result, lottery game, Lotto game, Mega, win cash, Lotto, play mega fortune, mega fortune game, mega fortune app, 
          mega fortune online, mega fortune free, mega fortune online slot, jackpot mega fortune, mega fortune online casino. digital lottery Ghana, online lottery Ghana, 
          lottery app Ghana, lottery games Ghana, digital lottery tickets Ghana, lotto Ghana, iLotto Ghana, Digital Lotto Ghana, Lotto World Ghana, National Lottery Authority Ghana, 
          WinLotto Ghana, Golden Chance Lotto Ghana, online lotto, digital lottery, lottery app, digital lottery game, digital lottery ticket, lottery game online, 
          digital lotto tickets, digital scratch off tickets, online lottery games, online lottery tickets, online lotto tickets, lottery website, online lotto games, 
          e-lotto game, online lottery play, lottery website free, buy national lottery online, digital banker lotto app, digital banker lottery online, 
          digital banker lottery tickets, digital banker lotto winning numbers, digital banker lotto review, digital banker lotto winners, 
          online lottery in Ghana, digital lottery tickets in Ghana, online lottery tickets in Ghana, electronic lottery in Ghana, digital lottery games in Ghana, 
          virtual lottery in Ghana, internet lottery in Ghana, mobile lottery in Ghana, mobile lottery games in Ghana, digital lotto in Ghana, digital lotto apps in Ghana, 
          online lotto in Ghana. '
          />
        </Helmet>
      ) : null}
      <ApolloProvider client={client}>
        <Auth0ProviderWithHistory>
          <Provider store={store}>
            <App />
          </Provider>
        </Auth0ProviderWithHistory>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
)
