import React from "react";

import { motion } from "framer-motion";
const Gallery = () => {
  return (
    <div className="py-32">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className=" w-9/12 mx-auto"
      >
        <h1 className="uppercase text-center text-5xl font-bold">Gallery</h1>
        <div className=" grid  md:grid-cols-2 gap-6 mt-16"></div>
      </motion.div>
    </div>
  );
};

export default Gallery;
