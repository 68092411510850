import bg from '../assets/images/signin.jpg'
import { motion } from 'framer-motion'
import { useState, Fragment } from 'react'
import { registerUser } from '../auth/userSlice'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Listbox, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'
import Disclaimer from '../components/Disclaimer'
import { isMobileSafari, isSafari } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { trackEvent } from "../utils/GoogleAnalytics";

// import PasswordChecklist from "react-password-checklist";

const networks = [
  {
    id: 0,
    name: 'Select Momo Nekwork',
    value: ''
  },
  {
    id: 1,
    name: 'Mtn',
    value: 'mtn-gh'
  },
  {
    id: 2,
    name: 'Vodafone',
    value: 'vodafone-gh'
  },
  {
    id: 3,
    name: 'AirtelTigo',
    value: 'tigo-gh'
  }
]
function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}
const Signin = () => {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState(networks[0])
  const [isOTPRequested, setIsOTPRequested] = useState(false)
  // const [checked, setChecked] = useState(false);
  const [tempPhone, setTempPhone] = useState('')
  const [isDisclaimerOpen, setDisclaimerIsOpen] = useState(false)

  const navigate = useNavigate()

  const [users, setValues] = useState({
    // phone_number: 0,
    first_name: '',
    middle_name: null,
    surname: '',
    user_type: 'customer',
    platform: 'web',
    // dob: '',
    consent_given: true,
    otp: '',
    otp_hashed: '',
    otp_expire_at: 0
  })

  const onChange = e => {
    setValues({
      ...users,
      [e.target.name]:
        e.target.name === 'consent_given' ? e.target.checked : e.target.value
    })
  }

  const registerNewUser = async e => {
    let phone = '233' + tempPhone.substring(1)

    e.preventDefault()
    try {
      const res = await dispatch(
        registerUser({
          ...users,
          phone_number: Number(phone),
          momo_network: selected.value
        })
      ).unwrap()
      toast.success(`OTP has been sent to phone number`)
      setIsOTPRequested(true)
      localStorage.setItem('phone_number', Number(phone))
      setValues({
        ...users,
        otp_hashed: res?.data.otp_hashed,
        otp_expire_at: res?.data.expires_at
      })
      // console.log('First Request', users)
      // console.log(users.consent_given)
    } catch (error) {
      toast.error(`${error.message}`)
    }
  }

  const verifyOTP = async e => {
    e.preventDefault()
    const res = await dispatch(
      registerUser({
        ...users,
        phone_number: localStorage.getItem('phone_number'),
        momo_network: selected.value
      })
    ).unwrap()

    toast.success(`Sign Up Successful`)
    // console.log(res)
    localStorage.setItem('token', res.access_token)
    localStorage.setItem('customer_id', res.data.id)
    localStorage.setItem('kyc', res.data.status)

    if (isMobileSafari || isSafari) {
      setDisclaimerIsOpen(true)
      navigate('/dashboard')
    } else {
      navigate('/dashboard')
    }
    // console.log(users)

    // console.log(users)
  }

  return (
    <motion.div
      initial={{ opacity: 0, x: '100%', scale: 1 }}
      animate={{ opacity: 1, x: '0' }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      exit={{ opacity: 0, y: '50%', transition: { duration: 0.1 } }}
      className='h-full  text-black bg-cover '
      style={{ backgroundImage: `url(${bg})` }}
    >
      {process.env.REACT_APP_ENVIRONMENT_NAME === 'PRODUCTION' ? (
        <Helmet>
          <title>Log In</title>
          <meta
            name='description'
            content='Log In page for Mega Fortune Lottery Web Application'
          />
        </Helmet>
      ) : null}

      <div className=' flex justify-center items-center  mx-auto'>
        <div
          className={
            isOTPRequested ? ' md:w-1/3  pt-28 pb-[34rem]' : 'md:w-1/3 py-28'
          }
        >
          <div className=' bg-white opacity-90 py-12 mb-36 shadow-2xl rounded-2xl px-12'>
            <h1 className='text-4xl text-center font-tommy'>
              Sign <span className='text-[#FF7300]'>Up</span>
            </h1>

            <div className='pt-8  mx-auto'>
              <form onSubmit={(e) => {
                e.preventDefault();
                if(isOTPRequested){
                  trackEvent({
                    category: 'Button',
                    action: 'Submit',
                    label: 'OTP Requested on Sign Up form',
                    value: 10,
                  });
                  verifyOTP(e);
                }else {
                  trackEvent({
                    category: 'Button',
                    action: 'Submit',
                    label: 'OTP Verified and Sign in authorized',
                    value: 11,
                  });
                  registerNewUser(e)
                }

                // isOTPRequested ? verifyOTP() : registerNewUser()
              }}>
                {isOTPRequested ? (
                  <div className='flex  items-center pl-6 border-2 rounded-3xl border-[#FF4100] mb-4 '>
                    <label className=' w-1/3 text-xs font-semibold' htmlFor=''>
                      OTP
                    </label>
                    <input
                      type='text'
                      name='otp'
                      value={users.otp}
                      onChange={onChange}
                      className={classNames(
                        'w-full focus:outline-none bg-transparent py-2 pr-6'
                      )}
                      placeholder='Enter the OTP sent your phone number'
                      required
                    />
                  </div>
                ) : (
                  <div className='mt-4 flex flex-col gap-2 '>
                    <div className='flex  items-center pl-6 border-2 rounded-3xl border-[#FF4100] mb-4 '>
                      <label
                        className=' w-1/3 text-xs font-semibold'
                        htmlFor=''
                      >
                        First Name
                      </label>
                      <input
                        type='text'
                        name='first_name'
                        value={users.first_name}
                        onChange={onChange}
                        className={classNames(
                          'w-full focus:outline-none focus: bg-transparent py-2 pr-6'
                        )}
                        required
                      />
                    </div>
                    <div className='flex  items-center pl-6 border-2 rounded-3xl border-[#FF4100] mb-4 '>
                      <label
                        className=' w-1/3 text-xs font-semibold'
                        htmlFor=''
                      >
                        Middle Name
                      </label>
                      <input
                        type='text'
                        name='middle_name'
                        value={users.middle_name}
                        onChange={onChange}
                        className={classNames(
                          'w-full focus:outline-none bg-transparent py-2 pr-6'
                        )}
                      />
                    </div>
                    <div className='flex  items-center pl-6 border-2 rounded-3xl border-[#FF4100] mb-4 '>
                      <label
                        className=' w-1/3 text-xs font-semibold'
                        htmlFor=''
                      >
                        Surname
                      </label>
                      <input
                        type='text'
                        name='surname'
                        value={users.surname}
                        onChange={onChange}
                        className={classNames(
                          'w-full focus:outline-none bg-transparent py-2 pr-6'
                        )}
                        required
                      />
                    </div>
                    {/* <div className='flex  items-center pl-6 border-2 rounded-3xl border-[#FF4100] mb-4 '>
                    <label className=' w-1/3 text-xs font-semibold' htmlFor=''>
                      Email
                    </label>
                    <input
                      type='email'
                      name='email'
                      value={users.email}
                      onChange={onChange}
                      className={classNames(
                        'w-full focus:outline-none bg-transparent py-2 pr-6'
                      )}
                      placeholder='Email'
                      required
                    />
                  </div> */}
                    {/* <div className='flex  items-center pl-6 border-2 rounded-3xl border-[#FF4100] mb-4 '>
                      <label className=' w-1/3 text-xs ' htmlFor=''>
                        National ID Number
                      </label>
                      <input
                        type='text'
                        name='national_id'
                        value={users.national_id}
                        onChange={onChange}
                        pattern={'/^[A-Z]{3}-[0-9]{9}-[0-9]{1}$/'}
                        className={classNames(
                          'w-full focus:outline-none bg-transparent py-2 pr-6'
                        )}
                        placeholder='National ID number'
                      />
                    </div> */}
                    {/* <div className="flex  items-center pl-6 border-2 rounded-3xl border-[#FF4100] mb-4 ">
                    <label className=" w-1/3 text-xs font-semibold" htmlFor="">
                      Password
                    </label>
                    <input
                      id="password"
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      max="10"
                      className={classNames(
                        "w-full focus:outline-none bg-transparent py-2 pr-6"
                      )}
                      placeholder="Password"
                      required
                    />

                    <button onClick={togglePassword} className=" text-xs px-2">
                      <img className="h-6 w-8" src={eye} alt="" />
                    </button>
                    <div className="hidden" id="count">
                      Length : 0
                    </div>
                  </div> */}
                    {/* <PasswordChecklist
                    rules={[
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "lowercase",
                    ]}
                    minLength={8}
                    value={password}
                  /> */}
                    <div className='flex  items-center pl-6 border-2 rounded-3xl border-[#FF4100] mb-4 '>
                      <label
                        className=' w-1/3 text-xs font-semibold'
                        htmlFor=''
                      >
                        Phone number
                      </label>
                      <input
                        type='text'
                        name='phone_number'
                        value={tempPhone}
                        pattern='[0-9]{3}[0-9]{3}[0-9]{4}'
                        title="Enter phone number in 10 digit phone number"
                        onChange={e => {
                          e.preventDefault()
                          setTempPhone(e.target.value)
                        }}
                        className={classNames(
                          'w-full focus:outline-none bg-transparent py-2 pr-6'
                        )}
                        required
                      />
                    </div>
                    {/* <div className='flex  items-center pl-6 border-2 rounded-3xl border-[#FF4100] mb-4 '>
                      <label
                        className=' w-1/3 text-xs font-semibold'
                        htmlFor=''
                      >
                        Date of Birth
                      </label>
                      <input
                        type='date'
                        name='dob'
                        value={users.dob}
                        onChange={onChange}
                        className={classNames(
                          'w-full focus:outline-none bg-transparent py-2 pr-6'
                        )}
                        required
                      />
                    </div> */}
                    <div className='flex  items-center pl-6 border-2 rounded-3xl border-[#FF4100] mb-4 '>
                      <label className=' w-1/3 text-xs  ' htmlFor=''>
                        Momo Network
                      </label>
                      <Listbox value={selected} onChange={setSelected}>
                        {({ open }) => (
                          <>
                            <div className='relative  w-full'>
                              <Listbox.Button className='relative cursor-default rounded-md   py-2 pl-3 pr-10 text-left     sm:text-sm'>
                                <span className='flex items-center'>
                                  <span
                                    className={classNames(
                                      'ml-3 block truncate',
                                      selected.name === 'Select Momo Nekwork'
                                        ? ' text-gray-400'
                                        : ' text-black'
                                    )}
                                  >
                                    {selected.name}
                                  </span>
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave='transition ease-in duration-100'
                                leaveFrom='opacity-100'
                                leaveTo='opacity-0'
                              >
                                <Listbox.Options className='absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                                  {networks.map(person => (
                                    <Listbox.Option
                                      key={person.id}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? 'text-white bg-[#FF4100]'
                                            : 'text-gray-900',
                                          'relative cursor-default select-none py-2 pl-3 pr-9'
                                        )
                                      }
                                      value={person}
                                      disabled={person.id === 0}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <div className='flex items-center'>
                                            <span
                                              className={classNames(
                                                selected
                                                  ? 'font-semibold'
                                                  : 'font-normal',
                                                'ml-3 block truncate',
                                                person.id === 0
                                                  ? ' text-gray-400'
                                                  : ''
                                              )}
                                            >
                                              {person.name}
                                            </span>
                                          </div>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? 'text-white'
                                                  : 'text-indigo-600',
                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                              )}
                                            ></span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                  </div>
                )}
                {isOTPRequested ? null : (
                  <div className='flex flex-row px-5'>
                    <input
                      type='checkbox'
                      name='consent_given'
                      // checked={users.consent_given}
                      value={users.consent_given}
                      onChange={onChange}
                      className='h-5'
                      required
                    />
                    <label
                      className=' w-1/2 mx-3 text-xs font-semibold'
                      htmlFor=''
                    >
                      By checking the checkbox you give your consent to use this
                      application
                    </label>
                  </div>
                )}
                <div className=' flex justify-center mt-6 '>
                  {isOTPRequested ? (
                    <button
                      type='submit'
                      // disabled={!isValid || pending}
                      className='hover:bg-[#FF4100] py-2 transition-all hover:text-white  bg-[#FF7300] text-white px-8 rounded-3xl font-bold '
                    >
                      Create Account
                    </button>
                  ) : (
                    <button
                      type='submit'
                      // disabled={!isValid || pending}
                      className='hover:bg-[#FF4100] py-2 transition-all hover:text-white  bg-[#FF7300] text-white px-8 rounded-3xl font-bold '
                    >
                      Request OTP
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Disclaimer isOpen={isDisclaimerOpen} setIsOpen={setDisclaimerIsOpen} />
    </motion.div>
  )
}

export default Signin
