import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { LogUserIn } from '../auth/userSlice'
import bg from '../assets/images/signin.jpg'
import { useNavigate } from 'react-router-dom'
import Disclaimer from '../components/Disclaimer'
import { Helmet } from 'react-helmet'
import { isMobileSafari, isSafari } from 'react-device-detect'
import { trackEvent } from "../utils/GoogleAnalytics";


function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isOTPRequested, setIsOTPRequested] = useState(false)
  const [countdown, setCountdown] = useState(15)
  const [disabled, setDisabled] = useState(true)
  // const [isOpen, setIsOpen] = useState(false)
  // const [userType, setUserType] = useState(null)
  const [isDisclaimerOpen, setDisclaimerIsOpen] = useState(false)

  const [tempPhone, setTempPhone] = useState('')

  const [users, setValues] = useState({
    // phone_number: '',
    platform: 'web',
    otp: '',
    otp_hashed: '',
    otp_expire_at: 0
  })

  const onChange = e => {
    isOTPRequested
      ? setValues({ ...users, [e.target.name]: e.target.value })
      : setValues({ ...users, [e.target.name]: Number(e.target.value) })
  }

  // const [getUserType, { data: customer }] = useLazyQuery(GET_USER_TYPE, {
  //   variables: {
  //     _userID: localStorage.getItem('customer_id')
  //   }
  // })

  // useEffect(() => {
  //   if (customer) {
  //     setUserType(customer[0]?.user_type)
  //   }
  // }, [customer])

  useEffect(() => {
    if (countdown === 0) {
      setDisabled(false)
    } else {
      const interval = setInterval(() => {
        setCountdown(countdown - 1)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [countdown])

  const loginUser = async e => {
    e.preventDefault()

    try {
      let phone = '233' + tempPhone.substring(1)

      const res = await dispatch(
        LogUserIn({ ...users, phone_number: Number(phone) })
      ).unwrap()
      toast.success(`OTP has been sent to phone number`)
      localStorage.setItem('phone_number', Number(phone))
      localStorage.setItem('platform', users.platform)
      setIsOTPRequested(true)
      setValues({
        ...users,
        otp_hashed: res?.data.otp_hashed,
        otp_expire_at: res?.data.expires_at
      })
    } catch (error) {
      toast.error("Account does not exist")
      // console.log(error)
    }
  }

  const verifyOTP = async e => {
    e.preventDefault()

    try {
      const res = await dispatch(
        LogUserIn({
          ...users,
          phone_number: localStorage.getItem('phone_number')
        })
      ).unwrap()

      // console.log(res)
      localStorage.setItem('token', res.access_token)
      localStorage.setItem('customer_id', res.data.id)
      localStorage.setItem('kyc', res.data.status)

      if (res?.data.user_type === 'customer') {
        if (isMobileSafari || isSafari) {
          setDisclaimerIsOpen(true)
          toast.success(`Log In Successful`)
          navigate('/dashboard')
        } else {
          toast.success(`Log In Successful`)
          navigate('/dashboard')
        }
      } else {
        // setIsOpen(true)
        setIsOTPRequested(false)
        setTempPhone('')
        setValues({
          ...users,
          platform: 'web',
          otp: '',
          otp_hashed: '',
          otp_expire_at: 0
        })
      }
      // console.log(users)
    } catch (error) {
      toast.error(`${error.message}`)
       // console.log(error)
    }
  }

  const resendOTP = async e => {
    setDisabled(true)
    e.preventDefault()
    try {
      const res = await dispatch(
        LogUserIn({
          phone_number: localStorage.getItem('phone_number'),
          platform: localStorage.getItem('platform')
        })
      ).unwrap()
      toast.success(`OTP has been sent to phone number`)

      setIsOTPRequested(true)
      setValues({
        ...users,
        otp_hashed: res?.data.otp_hashed,
        otp_expire_at: res?.data.expires_at
      })
    } catch (error) {
      toast.error(`${error.message}`)
      // console.log(users)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0, x: '100%', scale: 1 }}
      animate={{ opacity: 1, x: '0' }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      exit={{ opacity: 0, y: '50%', transition: { duration: 0.1 } }}
      className='h-full  text-black bg-cover '
      style={{ backgroundImage: `url(${bg})` }}
    >
      {process.env.REACT_APP_ENVIRONMENT_NAME === 'PRODUCTION' ? (
        <Helmet>
          <title>Sign Up</title>
          <meta
            name='description'
            content='Sign Up page for Mega Fortune Lottery Customers'
          />
        </Helmet>
      ) : null}

      <div className=' flex justify-center items-center mx-auto'>
        <div className=' md:w-1/3  pt-64 pb-[34rem] '>
          <div className=' bg-white opacity-90 py-12 shadow-2xl rounded-2xl px-12'>
            <h1 className='text-4xl text-center font-tommy'>
              Log <span className='text-[#FF7300]'>In</span>
            </h1>
            <div className='pt-8  mx-auto'>
              <form onSubmit={(e) => {
                e.preventDefault();
                if(isOTPRequested){
                  trackEvent({
                    category: 'Button',
                    action: 'Submit',
                    label: 'OTP Requested on Login form',
                    value: 12,
                  });
                  verifyOTP(e);
                }else{
                  trackEvent({
                    category: 'Button',
                    action: 'Submit',
                    label: 'OTP verified and user is logged in',
                    value: 13,
                  });
                  loginUser(e);
                }

                // isOTPRequested ? verifyOTP : loginUser
              }}>
                <div className='mt-4 flex flex-col gap-2 '>
                  <div className='flex  items-center pl-6 border-2 rounded-3xl border-[#FF4100] mb-4 '>
                    <label
                      className=' w-1/3 text-xs mx-2 font-semibold'
                      htmlFor=''
                    >
                      Phone number
                    </label>
                    <input
                      type='text'
                      name='phone_number'
                      value={tempPhone}
                      pattern='[0-9]{3}[0-9]{3}[0-9]{4}'
                      title="Enter phone number in 10 digit phone number"
                      onChange={e => {
                        e.preventDefault()
                        setTempPhone(e.target.value)
                      }}
                      className={classNames(
                        'w-full focus:outline-none bg-transparent py-2 pr-6'
                      )}
                      // pattern='/^\d{10}$/'
                      required
                    />
                  </div>
                  {isOTPRequested ? (
                    <div className='flex  items-center pl-6 border-2 rounded-3xl border-[#FF4100] mb-4 '>
                      <label
                        className=' w-1/3 text-xs font-semibold'
                        htmlFor=''
                      >
                        OTP
                      </label>
                      <input
                        type='text'
                        name='otp'
                        value={users.otp}
                        pattern='[0-9]{5}'
                      title="OTP should be 5 digits only"
                        onChange={onChange}
                        className={classNames(
                          'w-full focus:outline-none bg-transparent py-2 pr-6'
                        )}
                        placeholder='Enter the OTP sent your phone number'
                        required
                      />
                    </div>
                  ) : null}
                </div>
                <div className=' flex justify-center mt-6 '>
                  {isOTPRequested ? (
                    <div>
                      <button
                        type='submit'
                        // disabled={!isValid || pending}
                        className='hover:bg-[#FF4100] py-2 transition-all hover:text-white  bg-[#FF7300] text-white  lg:px-8 px-4 rounded-3xl font-bold mx-3'
                      >
                        Sign In
                      </button>
                      {disabled === false ? (
                        <button
                          disabled={disabled}
                          onClick={e => {
                            e.preventDefault()

                            resendOTP(e)
                          }}
                          // disabled={!isValid || pending}
                          className={
                            disabled
                              ? 'bg-grey text-white'
                              : 'hover:bg-[#FF4100] py-2 transition-all hover:text-white  bg-[#FF7300] text-white lg:px-8 px-4 rounded-3xl font-bold mx-3'
                          }
                        >
                          Resend OTP
                        </button>
                      ) : null}
                    </div>
                  ) : (
                    <button
                      type='submit'
                      // disabled={!isValid || pending}
                      className='hover:bg-[#FF4100] py-2 transition-all hover:text-white  bg-[#FF7300] text-white lg:px-8 px-4 rounded-3xl font-bold '
                    >
                      Request OTP
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* <Modal isOpen={isOpen} setIsOpen={setIsOpen} /> */}
        <Disclaimer
          isOpen={isDisclaimerOpen}
          setIsOpen={setDisclaimerIsOpen}
          message={
            'We recommend switching to Chrome or any other browser on your device for a better experience.'
          }
        />
      </div>
    </motion.div>
  )
}

export default Login
