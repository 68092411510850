import React from 'react'
import { Disclosure } from '@headlessui/react'
import { ArrowDownCircle } from 'react-feather'
import { Helmet } from 'react-helmet'

const faqs = [
  {
    question: 'What is Mega Fortune?',
    position: 1,
    answer:
      'Mega Fortune is a lottery game in which you pay to stake on numbers for the opportunity to win cash and other prizes.'
  },
  {
    question: 'How does one play?',
    answer:
      'You can by choosing a maximum of 6 numbers between 1 and 96. You can play Mega 1, Mega 2, Mega 3, Mega 4, Mega 5, Mega 6, Perm 2, Perm 3, Perm 4, Perm 5, Perm 6 and Banker. You can combine the game types and stake multiple plays at once.'
  },
  {
    question: 'How will I know if I win?',
    answer:
      'Draws are held at 7:30 PM every day. Winners are notified via SMS. The winning numbers are also published on the Mega Fortune website and social media pages.  '
  },
  {
    question: 'How long does it take to get paid? ',
    answer:
      'Winners are paid via Mobile Money to their registered numbers within 24 hours of the draw. Winners have 17 working days to collect their winnings from the bank when the winning is above GHS 5,000.'
  },
  {
    question: 'How do I claim my money when I win?',
    answer: `When you win a draw, the money is paid directly to your mobile money account. However, Mega Fortune Lottery reserves the right to carry out additional verification procedures for payouts exceeding GHS 5,000 or when there is a suspicion of fraud. 
      Please note that payouts exceeding GHS 5,000 can only be collected at the bank.`
  },
  {
    question: 'What happens if I lose a winning ticket?    ',
    answer:
      'Losing a hard copy lottery ticket is like losing cash. However, both the app and USSD secure your claims directly on the system. '
  },
  {
    question: 'Which networks can I play the game on?    ',
    answer: 'You can play Mega Fortune games on MTN, AirtelTigo, and Vodafone.'
  },
  {
    question: 'What time is the draw?',
    answer: 'Mega Fortune draw happens at 7:00 PM every day.  '
  },
  {
    question: 'Can I watch the live draw?',
    answer:
      'Yes. You can visit the head office of Fortune Synergy located at 3rd Bethel Street, North Legon and witness the live draw at 7:00 PM every day. You can also watch the live draw on all our social media handles and partner TV stations.'
  },
  {
    question: 'Can minors purchase Lottery tickets and/or claim prizes?',
    answer:
      'Tickets can be purchased as gifts for people under 18 years old, but not by minors themselves. Anyone under the age of 18 years old must have received the ticket as a gift to claim the prize.'
  },
  {
    question: 'How much should I have to play a game? ',
    answer:
      'Mega Fortune allows you to stake a game for as little as 1GHS. However, you cannot stake over 500GHS.'
  },
  {
    question: 'How do I contact Mega Fortune support? ',
    answer:
      'You can contact us at 030 290 9909. You can also send us an e-mail at info@fortunesynergy.com. Our office is located at 3 Bethel Street, North Legon – Accra. '
  },
  {
    question: 'Where can I find Mega Fortune to play? ',
    answer:
      'You can download the Mega Fortune App on your app store. Dial *824# to play with USSD. Our website www.megafortunelottery.com also offers you the opportunity to stake online. Mega Fortune Orange Kiosks are visible throughout the country and with the help of our agents, you can easily stake a game. We are differentiated from others by our remarkable Orange Kiosks with our logo.'
  },
  {
    question: 'Can I add games to my stake?',
    answer:
      'Yes. Mega Fortune gives you the opportunity to add as many games as you want and stake at once.'
  },
  {
    question:
      'I received an e-mail saying that I won the Lottery. Is this legitimate?',
    answer: `No! this is a common fraud. Mega Fortune only contacts winners via instant messages on their registered phone numbers or in-app notifications.
      Here are three things that you should do upon receipt of these kinds of e-mails:  
      1. Do not reply to these emails  
      2. Delete them without opening them  
      3. Add the address to your junk mail listing 
      `
  },
  {
    question: 'Where can I buy Mega Fortune Lottery tickets?',
    answer:
      'You can legally purchase tickets at any Mega Fortune owned and approved Orange Kiosk nationwide, or with any of our registered mobile agents using the Mega Fortune agent app. You can also purchase tickets on the Mega Fortune website at www.megafortunelottery.com '
  },
  {
    question: 'How does the Mega Fortune draw work?',
    answer: `Mega Fortune draws every day at 7:00 PM. We draw both Machine Numbers and Regular Numbers. 

      Regular Numbers – 6 balls are picked by draw attendees to show a set of winning numbers. Each attendee picks 1 ball. 
      
      Machine Numbers – 6 balls are picked by the lottery machine to show a different set of winning numbers. 
      
      Your winning numbers are based on whether you staked for Regular Numbers or Machine Numbers.`
  }
  // {
  //   question: 'What happens to my money if I do not win?    ',
  //   answer:
  //     'There are no losers when it comes to Mega Fortune lottery. If you do not win a draw, your stake is converted into bonus points which you can redeem and use to play again.'
  // }
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const Faqs = () => {
  return (
    <div className=' '>
      {process.env.REACT_APP_ENVIRONMENT_NAME === 'PRODUCTION' ? (
        <Helmet>
          <title>FAQs</title>
          <meta
            name='description'
            content='Frequently Asked Questions about Mega Fortune Lottery'
          />
        </Helmet>
      ) : null}

      <div className=' bg-primary-100 text-secondary-200 bg-gray-50 py-24'>
        <div className=' text-center text-5xl pt-12 uppercase font-bold'>
          <p>Frequently asked questions</p>
        </div>
      </div>
      <div className='mx-auto max-w-7xl pb-16 px-4  sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-5xl divide-y-2 divide-gray-200'>
          <dl className='space-y-6 divide-y divide-gray-200'>
            {faqs.map(faq => (
              <Disclosure
                as='div'
                defaultOpen={faq.position === 1}
                key={faq.id}
              >
                {({ open }) => (
                  <>
                    <dt className='text-lg rounded-lg py-2 px-4 text-white bg-[#FF7300]'>
                      <Disclosure.Button className='flex w-full items-start justify-between text-left '>
                        <span className='font-medium '>{faq.question}</span>
                        <span className='ml-6 flex h-7 items-center'>
                          <ArrowDownCircle
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden='true'
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as='dd' className='mt-3 pr-12'>
                      <p className='text-base '>{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default Faqs
