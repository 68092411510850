import React from "react";
import { ArrowLeft } from "react-feather";
import { Outlet, useNavigate } from "react-router-dom";

const Game = () => {
  const navigate = useNavigate();
  return (
    <div className="-mt-5">
      <h1
        className="w-12 flex items-center hover:shadow-lg transition-all shadow-md justify-center h-12 bg-white rounded-full"
        onClick={() => navigate(-1)}
      >
        <ArrowLeft />
      </h1>
      <Outlet />
    </div>
  );
};

export default Game;
