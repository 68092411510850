import React, { useEffect } from 'react'
import dromo from '../../assets/images/games/dromo.png'
import bingo from '../../assets/images/games/bingo.png'
import happy from '../../assets/images/games/mrhappy.png'
import flow from '../../assets/images/games/overflow.png'
import sokoo from '../../assets/images/games/sokooo.png'
import twadi from '../../assets/images/games/twadi.png'
import cashout from '../../assets/images/games/CASHOUT.png'
import { useQuery, gql, useLazyQuery } from '@apollo/client'
import dayjs from 'dayjs'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
// import { DateRange } from "react-date-range";
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { FiX } from 'react-icons/fi'

const GET_STAKE_HISTORY = gql`
  query myStakeHistory(
    $customer_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
  ) {
    payment(
      order_by: [{ created_at: desc }]
      where: {
        game: { termintated: { _eq: false } }
        customer_id: { _eq: $customer_id }
        _and: [
          { created_at: { _gte: $start_date } }
          { created_at: { _lte: $end_date } }
        ]
      }
    ) {
      won_stakes: stakes_aggregate(where: { stake_status: { _eq: "won" } }) {
        aggregate {
          count
        }
      }
      game {
        results
        schedule_end
        run_end
        product {
          name
          image
        }
      }
      paid_at
      game_id
    }
  }
`

const GET_STAKE_HISTORY_DETAILS = gql`
  query myStakeHistoryDetails($game_id: uuid!, $customer_id: uuid) {
    game_by_pk(id: $game_id) {
      id
      event_code
      results
      schedule_end
      run_end
      product {
        image
        name
      }
      payments(
        where: { status: { _eq: "paid" }, customer_id: { _eq: $customer_id } }
      ) {
        stakes {
          picks
          stake_type
          amount_per_stake
          staked_amount
          reference
          winning_amount
        }
        total_stakes: stakes_aggregate {
          aggregate {
            sum {
              staked_amount
            }
          }
        }
        total_amount
      }
    }
  }
`

const History = () => {
  const [details, setDetails] = useState('')
  let [isOpen, setIsOpen] = useState(false)
  let [slipTotal, setSlipTotal] = useState(0)
  // let [totalWon, setTotalWon] = useState(0)

  // const [dett, setDett] = useState("");
  // const [showDetails, setShowDetails] = useState(true);
  const userDetails = JSON.parse(localStorage.getItem('user'))
  // console.log(userDetails)
  const today = new Date()
  const tomorrow = new Date(today) // create a new date object with the same date as today
  tomorrow.setDate(today.getDate() + 1) // set the date to tomorrow's date
  // console.log(dayjs(t).format('YYYY-MM-D'))

  const { loading, data, error } = useQuery(GET_STAKE_HISTORY, {
    variables: {
      customer_id: userDetails?.data.id,
      start_date: '2023-01-01',
      end_date: dayjs(tomorrow).format('YYYY-MM-D')
    }
  })

  useEffect(() => {
    console.log(error)
  }, [loading])
  // const det = data

  // console.log(det)

  const [stakeDetails] = useLazyQuery(GET_STAKE_HISTORY_DETAILS, {})

  // const [handleGetByDate] = useLazyQuery(GET_STAKE_HISTORY, {});
  // const [state] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: null,
  //     key: "selection",
  //   },
  // ]);

  const getAllData = async gameId => {
    // console.log(userDetails.data.id)
    // console.log(gameId)

    try {
      const response = await stakeDetails({
        variables: {
          customer_id: userDetails.data.id,
          game_id: gameId
        },

        onCompleted: result => {
          // result.game_by_pk.payments.map(item => {
          //   console.log(item.total_amount)
          // })
          // console.log(result)
          slipTotal = result.game_by_pk.payments.reduce(
            (acc, curr) => acc + curr.total_amount,
            0
          )
          // let totalWon = result.game_by_pk.payments.reduce(
          //   (acc, curr) => acc + curr.winning_amount,
          //   0
          // )
          // console.log(slipTotal)
          setSlipTotal(slipTotal)
          // setTotalWon(totalWon)
        }
      })

      // console.log(response)

      setDetails(response.data)
      openModal()
    } catch (error) {
      console.log(error)
    }
  }
  // const getByDate = async () => {
  //   const response = await handleGetByDate({
  //     variables: {
  //       customer_id: userDetails.data.id,
  //       start_date: dayjs(state[0].startDate).format("YYYY-MM-D"),
  //       end_date: dayjs(state[0].endDate).format("YYYY-MM-D  "),
  //     },
  //   });
  //   console.log(response.data);
  //   // setDetails(response.data);
  // };

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <div className='w-full'>
      {
        loading ?
          <div className='w-full aspect-video flex flex-col items-center justify-center'>
            <p>loading...</p>
          </div>
          :
          data && data?.payment.length !== 0 ? (
            <div>
              {/* {console.log(history)} */}
              <div className=''>
                {/* <div>
              {showDetails ? (
                <div className="flex flex-col">
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => setState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                    color="#FF4100"
                    rangeColors="[#FF4100]"
                  />
                  <button onClick={() => getByDate()}>click me</button>
                </div>
              ) : (
                ""
              )}
            </div> */}
                <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 xl:gap-x-16 cursor-pointer bg-white py-6 px-1 overflow-y-scroll overflow-x-hidden'>
                  {data?.payment.map((item, key) => (
                    <div
                      key={key}
                      onClick={() => getAllData(item.game_id)}
                      className='flex border-2 justify-between relative bg-white hover:shadow-lg transition-all rounded-lg py-3 px-5 items-center mb-3'
                    >
                      <div className=' absolute top-50 -left-6 h-10 w-10 border-2 bg-white rounded-full'></div>
                      <div className=' absolute top-50 -right-6 h-10 w-10 border-2 bg-white rounded-full'></div>
                      <div className=' absolute top-50 -left-7 h-10 w-10 rounded-full bg-white '></div>
                      <div className=' absolute top-50 -right-10 h-10 w-10 rounded-r-full bg-white '></div>
                      <div className=' bg-white flex mr-4 rounded-full border-2 border-[#043060] p-2 '>
                        <img
                          className='h-12 w-12'
                          src={
                            item?.game.product.image ===
                              'c32f09c4b7014b1eb81158aeb9f08623.jpg'
                              ? sokoo
                              : item?.game.product.image ===
                                '11ebd7ea44474a00b92f6a715fd82d75.jpg'
                                ? dromo
                                : item?.game.product.image ===
                                  '02782d2986724068955e83d424d576fc.jpg'
                                  ? twadi
                                  : item?.game.product.image ===
                                    'e036100c40f548a4b09b120cc17f5ea1.jpg'
                                    ? happy
                                    : item?.game.product.image ===
                                      '13c38e6ee09e44759ccc41272dc14a3e.jpg'
                                      ? bingo
                                      : item?.game.product.image ===
                                        '21c342244576481f8eaf2805efd0f478.jpg'
                                        ? flow
                                        : item?.game.product.image ===
                                          'af5fdac5eda944b5aafd8429e1648fd5.jpg'
                                          ? cashout
                                          : ''
                          }
                          alt='Game of the Day'
                        />
                      </div>
                      <div className='text-[#043060]'>
                        <h2 className=' md:text-xl text-lg font-tommy uppercase '>
                          {item.game.product.name}
                        </h2>
                        <p className='text-sm'>
                          {item.paid_at
                            ? dayjs(item.paid_at).format('D MMM YYYY ')
                            : 'Not Paid'}
                        </p>
                      </div>
                      <div className='flex'>
                        {[1, 2, 3, 4].map((item, key) => (
                          <p
                            key={key}
                            className=' py-1 px-1 mx-1 text-sm  bg-gray-50'
                          >
                            *
                          </p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {details ? (
                <div>
                  <Transition appear show={isOpen} as={Fragment}>
                    <Dialog as='div' className='relative z-10' onClose={closeModal}>
                      <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                      >
                        <div className='fixed inset-0 bg-black bg-opacity-25' />
                      </Transition.Child>

                      <div className='fixed inset-0 overflow-y-scroll'>
                        <div className='flex min-h-full items-center justify-center p-4 text-center'>
                          <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 scale-95'
                            enterTo='opacity-100 scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 scale-100'
                            leaveTo='opacity-0 scale-95'
                          >
                            <Dialog.Panel className='w-full max-w-2xl transform overflow-scroll rounded-[30px] bg-white text-left align-middle shadow-xl transition-all'>
                              <Dialog.Title
                                as='h3'
                                className='text-lg font-medium leading-6 text-gray-900'
                              >
                                <div className='px-5 py-5 flex flex-row justify-end'>
                                  <FiX
                                    className='cursor-pointer'
                                    onClick={e => {
                                      e.preventDefault()
                                      closeModal()
                                    }}
                                  />
                                </div>
                              </Dialog.Title>
                              <div className='mt-2'>
                                <div className='py-6 text-[#043060] bg-white shadow-lg '>
                                  <div className=' flex  w-10/12 mx-auto  '>
                                    <div className='p-6 w-10/12  mx-auto flex gap-6 items-center  '>
                                      <div className='bg-white rounded-full border-4 p-2 border-[#FF7300]/60'>
                                        <img
                                          className='h-12 w-12'
                                          src={
                                            details.game_by_pk.product.image ===
                                              'c32f09c4b7014b1eb81158aeb9f08623.jpg'
                                              ? sokoo
                                              : details.game_by_pk.product.image ===
                                                '11ebd7ea44474a00b92f6a715fd82d75.jpg'
                                                ? dromo
                                                : details.game_by_pk.product.image ===
                                                  '02782d2986724068955e83d424d576fc.jpg'
                                                  ? twadi
                                                  : details.game_by_pk.product.image ===
                                                    'e036100c40f548a4b09b120cc17f5ea1.jpg'
                                                    ? happy
                                                    : details.game_by_pk.product.image ===
                                                      '13c38e6ee09e44759ccc41272dc14a3e.jpg'
                                                      ? bingo
                                                      : details.game_by_pk.product.image ===
                                                        '21c342244576481f8eaf2805efd0f478.jpg'
                                                        ? flow
                                                        : details.game_by_pk.product.image ===
                                                          'af5fdac5eda944b5aafd8429e1648fd5.jpg'
                                                          ? cashout
                                                          : ''
                                          }
                                          alt='Mega Fortune Lottery Games'
                                        />
                                      </div>
                                      {details ? (
                                        <div>
                                          <h2 className='uppercase text-2xl font-tommy'>
                                            {details.game_by_pk.product.name}
                                            {/* {console.log(details)} */}
                                          </h2>
                                          <p className=' text-[#043060]/40'>
                                            #{details.game_by_pk.event_code}
                                          </p>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                  <div className='w-10/12 mx-auto  border-t-2 pt-3 border-dashed'>
                                    <div className=' flex justify-between'>
                                      <p>Winning Numbers</p>
                                      <div className='flex'>
                                        {details?.game_by_pk.results ? (
                                          details?.game_by_pk.results.map(
                                            (item, key) => (
                                              <p
                                                key={key}
                                                className='flex justify-center items-center text-white h-6 w-6 mr-1 text-sm rounded-full bg-[#FF7300]'
                                              >
                                                {item}
                                              </p>
                                            )
                                          )
                                        ) : (
                                          <p className='flex justify-center items-center text-white h-6 mr-1 text-sm px-3 py-2 rounded-lg bg-[#FF7300]'>
                                            * * * * * *
                                          </p>
                                        )}
                                      </div>
                                    </div>

                                    <div className=' flex justify-between'>
                                      <p>Date Drawn</p>
                                      <p className=' font-tommy'>
                                        {dayjs(
                                          details?.game_by_pk.schedule_end
                                        ).format('D MMM YYYY')}
                                      </p>
                                    </div>
                                    {/* <div className=' flex justify-between'>
                                  <p>Time Drawn</p>
                                  <p className=' font-tommy'>
                                    {' '}
                                    {dayjs(details?.game_by_pk.run_end).format('hh:mm A')}
                                  </p>
                                </div> */}
                                  </div>
                                  {details ? (
                                    <div className='w-10/12 mt-4 mx-auto  border-t-2 pt-3 border-dashed'>
                                      {details.game_by_pk?.payments.map(
                                        (item, key) => (
                                          <div key={key}>
                                            {item?.stakes.map((stake, key) => (
                                              <div key={key} className=' mb-7'>
                                                <div className=' flex justify-between'>
                                                  <p>Numbers</p>
                                                  <div className=' flex'>
                                                    {stake?.picks.map(
                                                      (item, key) => (
                                                        <div
                                                          key={key}
                                                          className='flex x justify-end'
                                                        >
                                                          <p className=' h-6 w-6 m-1 flex items-center justify-center text-sm text-[#FF7300]  bg-[#FF7300]/10'>
                                                            {item}
                                                          </p>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                                <div className=' flex justify-between'>
                                                  <p>Game Type</p>
                                                  <p className=' font-tommy'>
                                                    {stake?.stake_type}
                                                  </p>
                                                </div>
                                                <div className=' flex justify-between'>
                                                  <p>Amount</p>
                                                  <p className=' font-tommy'>
                                                    GHS {stake?.staked_amount}
                                                  </p>
                                                </div>
                                                <div className=' flex justify-between'>
                                                  <p>Reference:</p>
                                                  <p className=' font-tommy'>
                                                    #{stake?.reference}
                                                  </p>
                                                </div>
                                                <div className=' flex justify-between'>
                                                  <p>Amount Won:</p>
                                                  <p className=' font-tommy'>
                                                    GHS&nbsp;{stake?.winning_amount}
                                                  </p>
                                                </div>
                                              </div>
                                            ))}
                                            <div className=' mt-4 mx-auto mb-5 border-b-2 pt-3 border-dashed'>
                                              <div className=' flex justify-between'>
                                                <p>Total Amount Played</p>
                                                <p className=' font-tommy'>
                                                  GHS {item?.total_amount}
                                                </p>
                                              </div>

                                              {/* <div className=" flex text-[#FF7300] justify-between">
                      <p>Total Amount Won</p>
                      <p className=" font-tommy">GHS 1,207.50</p>
                    </div> */}
                                            </div>
                                          </div>
                                        )
                                      )}
                                      {/* <div className=' mt-4 mx-auto mb-5 border-b-2 pt-3'>
                                    <div className=' flex justify-between'>
                                      <p>Slip Total</p>
                                      <p className=' font-tommy'>
                                        GHS{slipTotal}
                                      </p>
                                    </div>
                                  </div> */}
                                      {/* <div className=' mt-4 mx-auto mb-5 border-b-2 pt-3'>
                                    <div className=' flex justify-between'>
                                      <p>Total Won</p>
                                      <p className=' font-tommy'>
                                        GHS{totalWon}
                                      </p>
                                    </div>
                                  </div> */}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className=' flex justify-center items-center h-[670px] text-4xl'>
              You currently have no game history
            </div>
          )}
    </div>
  )
}

export default History
