import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { removeAllGames, removeGame, stakePayment } from './dashboard/gameSlice'
import { useEffect } from 'react'
import dromo from '../assets/images/games/dromo.png'
import bingo from '../assets/images/games/bingo.png'
import happy from '../assets/images/games/mrhappy.png'
import flow from '../assets/images/games/overflow.png'
import sokoo from '../assets/images/games/sokooo.png'
import twadi from '../assets/images/games/twadi.png'
import cashout from '../assets/images/games/CASHOUT.png'
import PhoneNumberModal from '../components/PhoneNumberModal'
import Disclaimer from '../components/Disclaimer'
import { isMobileSafari, isSafari } from 'react-device-detect'

const AddGame = () => {
  const navigate = useNavigate()
  const games = useSelector(state => state.game)
  const [isOpen, setIsOpen] = useState(false)
  const [payingNumber, setPayingNumber] = useState(
    localStorage.getItem('phone_number')
  )
  const [isDisclaimerOpen, setDisclaimerIsOpen] = useState(false)

  // const { images } = useSelector((state) => state.user);
  // const gameImage = images.find(
  //   (element) => element.file_name === data?.game[0].product.image
  // );

  useEffect(() => {
    if (games.games.length === 0) {
      navigate('/dashboard/game')
    }
  }, [games.games, navigate])
  const dispatch = useDispatch()

  // console.log(games)

  const confirmStake = async () => {
    // let phone = payingNumber.substring(1)

    // console.log(payingNumber)

    if (isMobileSafari || isSafari) {
      setDisclaimerIsOpen(true)
    } else {
      const stakes = games.games.map(game => ({
        stake_type: game.mode,
        picks: game.picks,
        amount_per_stake: Number(game.amount)
      }))
      const payment = {
        game_id: games.games[0].gameId,
        stakes,
        momo_number: Number(payingNumber),
        // beneficiary_phone: Number(payingNumber)
      }
      const res = await dispatch(stakePayment(payment))
      if (res.type === 'stake/payment/rejected') {
        toast.error(res.payload.message)
        // console.log(res)
      } else {
        dispatch(removeAllGames())
        // console.log(res)
        window.open(res.payload.data.checkout_url, '_blank')
      }
    }

    // console.log(payment)
  }
  const addGameHandler = () => {
    if (games.games.length >= 4) {
      toast.error(`Sorry you cannot select more than 4 games`)
    } else {
      navigate('/dashboard/game')
    }
  }
  const getTotal = () => {
    let amt = games.games.map(game => game.payableAmount)
    let sum = 0
    for (let i = 0; i < amt.length; i++) {
      sum += amt[i]
    }
    return sum
  }

  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='shadow-xl bg-white py-10 rounded-xl px-5 flex flex-col items-center'>
        <h2 className='text-2xl text-[#043060] font-tommy flex items-center justify-center pb-5'>
          Add another game?
        </h2>
        {games.games.map(game => (
          <div className='relative bg-[#043060]/10 w-full flex left-0 items-center mb-5'>
            <div className='absolute -left-2  flex flex-col gap-2 items-center justify-center'>
              <p className=' bg-white h-4 w-4 rounded-full'></p>
              <p className=' bg-white h-4 w-4 rounded-full'></p>
              <p className=' bg-white h-4 w-4 rounded-full'></p>
              <p className=' bg-white h-4 w-4 rounded-full'></p>
            </div>
            <div className='flex items-center bg-white  h-24 w-24 rounded-full py-3 px-4'>
              <img
                className=' object-contain '
                src={
                  game.image === 'c32f09c4b7014b1eb81158aeb9f08623.jpg'
                    ? sokoo
                    : game.image === '11ebd7ea44474a00b92f6a715fd82d75.jpg'
                    ? dromo
                    : game.image === '02782d2986724068955e83d424d576fc.jpg'
                    ? twadi
                    : game.image === 'e036100c40f548a4b09b120cc17f5ea1.jpg'
                    ? happy
                    : game.image === '13c38e6ee09e44759ccc41272dc14a3e.jpg'
                    ? bingo
                    : game.image === '21c342244576481f8eaf2805efd0f478.jpg'
                    ? flow
                    : game.image === 'af5fdac5eda944b5aafd8429e1648fd5.jpg'
                    ? cashout
                    : ''
                }
                alt='Game of The Day'
              />
            </div>
            <div className='text-[#043060]'>
              <h1 className='  font-tommy'>
                {game.name} - {game.mode}
              </h1>
              <p className=' text-sm'>GHS {game.payableAmount}</p>
              <div className=' grid grid-cols-4 justify-start gap-1 w-[200px] h-[35px] mx-5 overflow-auto '>
                {game.picks.map((number, key) => (
                  <p key={key} className=' rounded-full  text-white  flex items-center justify-center bg-[#043060] h-8 w-8'>
                    {number}
                  </p>
                ))}
              </div>
            </div>
            <div
              onClick={() => dispatch(removeGame(game))}
              className=' bg-white h-8 w-8 rounded-full cursor-pointer text-red-600 flex justify-center relative right-3 font-tommy text-xl'
            >
              x
            </div>
            <div className='absolute -right-2  flex flex-col gap-2 items-center justify-center'>
              <p className=' bg-white h-4 w-4 rounded-full'></p>
              <p className=' bg-white h-4 w-4 rounded-full'></p>
              <p className=' bg-white h-4 w-4 rounded-full'></p>
              <p className=' bg-white h-4 w-4 rounded-full'></p>
            </div>

            <div className='absolute left-32  flex flex-col gap-20 items-center justify-between'>
              <p className=' bg-white h-5 w-5 rounded-full'></p>
              <p className=' bg-white h-5 w-5 rounded-full'></p>
            </div>
          </div>
        ))}

        <div className='w-full'>
          <div className='flex pt-12 text-[#FF7300] pb-4 justify-between'>
            <p>Total</p>
            <p className=' font-tommy'>GHS {getTotal()}</p>
          </div>
          <div className=' gap-4 flex flex-col  '>
            <button
              onClick={addGameHandler}
              className=' w-full bg-gradient-to-r from-[#FFAC00] to-[#FF4100] text-white font-tommy rounded-3xl py-2'
            >
              Add a Game
            </button>

            <button
              onClick={e => {
                e.preventDefault()
                // setIsOpen(true)
                confirmStake()             
              }}
              className=' w-full border-2 border-[#FF7300] text-[#FF7300] font-tommy rounded-3xl py-2'
            >
              Next
            </button>
          </div>
        </div>
      </div>
      {/* <PhoneNumberModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        payingNumber={payingNumber}
        setPayingNumber={setPayingNumber}
        submitMethod={confirmStake}
      /> */}

      <Disclaimer isOpen={isDisclaimerOpen} setIsOpen={setDisclaimerIsOpen} message={
        "We recommend switching to Chrome or any other browser on your device to enable payment for your stakes"
      } />
    </div>
  )
}

export default AddGame
