import React from 'react'
import { Helmet } from 'react-helmet'

const Privacy = () => {
  return (
    <div>
      {process.env.REACT_APP_ENVIRONMENT_NAME === 'PRODUCTION' ? (
        <Helmet>
          <title>Privacy Policy</title>
          <meta
            name='description'
            content='Privacy Policy of Mega Fortune Lottery'
          />
        </Helmet>
      ) : null}

      <div className=' '>
        <div className=' bg-primary-100 text-secondary-200 bg-gray-50 py-24'>
          <div className=' text-center uppercase text-5xl pt-12  font-bold'>
            <p>Mega Fortune Privacy Policy </p>
          </div>
        </div>
        <div className='mx-auto max-w-7xl pb-16 px-4  sm:px-6 lg:px-8'>
          <div className='mx-auto max-w-5xl divide-y-2 divide-gray-200'>
            <div className='space-y-4'>
              {/* <h1 className=' text-3xl font-semibold'>TABLE OF CONTENT</h1>
              <ol  class="list-decimal">
                <li>Our Personal Data Privacy Policy</li>
                <li>Scope of Privacy Policy</li>
                <li>Types of Personal Data We Collect</li>
                <li>How We Collect Data</li>
                <li>Why We Collect Personal Data</li>
                  <ul class="relative list-disc left-5">
                    <li>To provide our services</li>
                    <li>To maintain and improve upon our services</li>
                    <li>Communicate with users</li>
                    <li>Conducting research and analysis</li>
                  </ul>
                <li>When We Process Personal Data</li>
                  <ul class="relative list-disc left-5">
                    <li>Legal Obligation</li>
                    <li>Consent</li>
                    <li>Internal Use</li>
                  </ul>
                <li>How We Secure Personal Data</li>
                <li>How Long We Keep Personal Data</li>
                  <ul class="relative list-disc left-5">
                    <li>Our Cookies Policy</li>
                  </ul>

                <li>Rights of the User</li>
                <li>Policy Amendments and Revision</li>
              </ol> */}

              <h3 className=' text-xl font-semibold uppercase'>
                Our Personal Data Privacy Commitment{' '}
              </h3>
              <p>
                This privacy policy explains how Mega Fortune collects, stores,
                and uses the data you give to us when you visit or use our
                website or mobile application. In addition, this privacy policy
                highlights how we control the collection, correction, and/or
                deletion of information that personally belongs to customers.
                Mega Fortune uses technology and software to protect your
                information and ensure its security and confidentiality. We will
                not use or share the information with anyone except as described
                in this Privacy Policy.
              </p>
              <p> We will update you when this privacy policy changes. </p>

              <h3 className=' text-xl font-semibold uppercase'>
                Scope of Privacy Policy
              </h3>
              <p>
                This Privacy Policy applies to personal information and other
                information collected by Mega Fortune or its service providers
                using the Mega Fortune platform in part or in full through or
                about:
              </p>
              <ul class='relative list-disc left-5'>
                <li>
                  Visitors to any Mega Fortune facilities, or users of, its
                  products, and websites.
                </li>
                <li>
                  Existing customers that are using the services pursuant to a
                  written agreement with Mega Fortune written physically or
                  virtually (online);
                </li>
                <li>
                  Prospective and/or existing customers using the services
                  pursuant to a browser wrap, or other online agreement.
                </li>
                <li>Other service providers and partners of Mega Fortune.</li>
                <li>
                  Other third parties that use any of Mega Fortune platform
                  implemented on-site or Software-as-a-service (SaaS), including
                  but not limited to the end users of prospective and existing
                  customers.
                </li>
              </ul>
              <h2 className='text-xl font-semibold uppercase'>
                Types of Personal Data We Collect
              </h2>
              <h4 className='text-lg font-semibold uppercase'>AGENT</h4>
              <ul className='relative list-disc left-5'>
                <li>Full Name </li>
                <li>Phone number </li>
                <li>E-mail address </li>
                <li>National ID (Ghana Card) </li>
                <li>Selfie Photograph </li>
                <li>Mobile Money Number </li>
                <li>Town/City </li>
                <li>Region </li>
              </ul>

              <h4 className='text-lg font-semibold uppercase'>CUSTOMER</h4>
              <ul className='relative list-disc left-5'>
                <li>Full Name </li>
                <li>Phone number </li>
                <li>E-mail address </li>
                <li>National ID (Ghana Card) </li>
                <li> Digital Address </li>
                <li>Region </li>
                <li>Town/City </li>
                <li>Mobile Money Number </li>
              </ul>

              <h3 className='text-xl font-semibold uppercase'>
                How We Collect Data
              </h3>
              <p>
                We may store your data when you visit or use our website or
                mobile application. Mega Fortune collects your data when you
                create an account or use any of our services. We may also
                collect data from or about you when you contact us through our
                customer support team and when you respond to surveys.{' '}
              </p>
              <p>
                Mega Fortune may monitor or record any communications with you
                or anyone acting on your behalf. This is done for quality and
                training purposes. By communicating with Mega Fortune, you
                acknowledge that your communication may be overheard, monitored,
                or recorded without pre informing you.
              </p>
              <p>
                Mega Fortune may contact you electronically to troubleshoot
                problems with your account, to resolve a dispute, to poll your
                opinions through surveys or questionnaires, or as otherwise
                necessary to service your account. Furthermore, we may contact
                you to offer coupons, discounts, and promotions, and inform you
                about Mega Fortune Services.{' '}
              </p>
              <p>
                We may contact you as necessary to enforce our policies,
                applicable law, or any agreement we may have with you. When
                contacting you via phone, to reach you as efficiently as
                possible, we may use pre-recorded calls and text messages. Where
                applicable and permitted by law, you may decline to receive
                certain communications.{' '}
              </p>

              <h3 className='text-xl font-semibold uppercase'>
                Why We Collect Personal Data{' '}
              </h3>
              <div className=' space-y-3'>
                <h4 className='text-lg font-semibold'>
                  To provide our services
                </h4>
                <p>
                  We use data provided to facilitate the processing of user’s
                  requests, set up and manage user accounts.
                </p>

                <h4 className='text-lg font-semibold'>
                  To maintain and improve upon our services.
                </h4>
                <p>
                  We collect user information to optimize marketing and
                  advertising of products and services we provide as well as
                  customize our website and mobile application according to your
                  preferences.
                </p>

                <h4 className='text-lg font-semibold'>
                  Communicate with users.
                </h4>
                <p>
                  We use data collected to communicate with users in the form of
                  notifications on existing offers, new features, or updates.
                  Users may also be contacted to participate in surveys or send
                  you promotional materials.
                </p>

                <h4 className='text-lg font-semibold'>
                  Conducting research and analysis
                </h4>
                <p>
                  We may use your data to enable us conduct market research and
                  further analysis to further improve upon our products and
                  services.
                </p>

                <h3 className='text-xl font-semibold uppercase'>
                  When We Process Personal Data{' '}
                </h3>
                <h4 className='text-lg font-semibold'>Legal Obligation</h4>
                <p>
                  In cases where the processing of personal data is necessary
                  for the performance of an obligation imposed by law on Mega
                  Fortune, we rely on Legal Obligation as a basis to process the
                  personal information you have provided. To comply with the
                  law, information collected will be used fairly, stored safely
                  and not disclosed to any other person unlawfully.
                </p>
                <h4 className='text-lg font-semibold'>Consent</h4>
                <p>
                  Mega Fortune only processes personal data with the consent of
                  the individual user. Where the data is classified as
                  sensitive, express consent shall be obtained.
                </p>
                <h4 className='text-lg font-semibold'>Internal Use </h4>
                <p>
                  Members of our staff may require access to user personal data
                  to perform their jobs and duties, but this is limited to our
                  staff having only “relevant access”. This means that staff
                  will only be able to access information required to perform
                  activities within the scope of their job functions.
                </p>
                <p>
                  In addition, our partner merchants and sales agents will use
                  information provided by users to provide quotes and eventual
                  processing of users’ requests for our services.
                </p>
                <h3 className='text-xl font-semibold uppercase'>
                  How We Secure Personal Data
                </h3>
                <p>
                  Mega Fortune takes data security and confidentiality
                  seriously. At Mega Fortune, we use reasonable security
                  measures to protect the confidentiality of personal
                  information under our control and appropriately limit access
                  to it.
                </p>
                <p>
                  Mega Fortune cannot ensure or warrant the security of any data
                  you transmit to us, and you do so at your own risk. We use a
                  variety of information security measures to protect your
                  online transactions with us.
                </p>
                <p>
                  Our website uses encryption technology, such as Secure Sockets
                  Layer (SSL), to protect your personal data during data
                  transport. SSL protects information you submit via our
                  websites such as ordering information, including your name,
                  email address and your payment details.
                </p>
                <h3 className='text-xl font-semibold uppercase'>
                  How Long We Keep Personal Data{' '}
                </h3>
                <p>
                  Mega Fortune will keep your data for as long as your account
                  is active or as needed to provide our services, comply with
                  our legal obligations, resolve disputes, and enforce our
                  agreement. If at some point you want to restrict us from
                  collecting and using your data, contact Mega Fortune at
                  info@fortunesynergy.com.
                </p>
                <h3 className='text-xl font-semibold uppercase'>
                  Our Cookies Policy
                </h3>
                <p>
                  Mega Fortune collects data through cookies. When you allow our
                  website to use cookies, you also allow us to use the data it
                  collects from you. The information we collect from cookies may
                  include your IP address, browser, and device characteristics,
                  referring URLs, and a record of your interactions with our
                  website.
                </p>
                <p>
                  Cookies do not allow Mega Fortune to gain control of your
                  computer. We use cookies to create a better experience for you
                  on our website by monitoring which pages you spend more time
                  on.
                </p>
                <h3 className='text-xl font-semibold uppercase'>
                  Rights of the User
                </h3>
                <p>
                  Customers and users of Mega Fortune products and services have
                  the right to access any personal data that is kept about them
                  either on a computer system or in certain files.
                </p>
                <p>All customers and users are entitled to know:</p>
                <ul className='relative list-disc left-5'>
                  <li>
                    what information Mega Fortune holds and processes about them
                    and why.
                  </li>
                  <li>how to gain access to it. </li>
                  <li>how to keep it up to date. </li>
                  <li>
                    what Mega Fortune is doing to comply with its data
                    protection obligations.{' '}
                  </li>
                </ul>
                <p>
                  Mega Fortune therefore provides all customers and other
                  relevant users with a standard form of notification. This will
                  state all the types of data Mega Fortune holds and processes
                  about them, and the reasons for which it is being processed.
                </p>
                <p>
                  Mega Fortune aims to comply with users’ requests for access to
                  personal information as quickly as possible but will ensure
                  that access to personal information will be within this policy
                  in place
                </p>
                <p>
                  When filling the form for your account to be created or
                  filling in a form on our website, you can check if you can
                  leave some of the questions unanswered.
                </p>
                <p>You may also choose to:</p>
                <ul className='relative list-disc left-5'>
                  <li>update and correct your personal information. </li>
                  <li>
                    cancel your account or request that we no longer use your
                    data to provide you services.{' '}
                  </li>
                </ul>
                <p>To do any of these, let us know by one of these methods:</p>
                <ul className='relative list-disc left-5'>
                  <li>
                    follow the directions in a marketing e-mail or direct mail
                    or mobile communication that you receive from us.{' '}
                  </li>
                  <li>
                    call Mega Fortune with your request and current contact
                    information.
                  </li>
                  <li>
                    send an e-mail with your request and current contact
                    information to info@fortunesynergy.com{' '}
                  </li>
                  <li>
                    send a letter with your request and current contact
                    information to our address.
                  </li>
                </ul>
                <h3 className='text-xl font-semibold uppercase'>
                  Policy Amendments and Revision
                </h3>

                <p>
                  This policy can be changed, modified, revised, or rescinded
                  completely by Mega Fortune at any time with appropriate
                  approvals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Privacy
