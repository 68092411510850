import React, { useState } from 'react'
import banner from '../assets/images/recBg.svg'
import perm2 from '../assets/images/games/perm-2.png'
import perm3 from '../assets/images/games/perm-3.png'
import perm4 from '../assets/images/games/perm-4.png'
import perm5 from '../assets/images/games/perm-5.png'
import perm6 from '../assets/images/games/perm-6.png'
import mega1 from '../assets/images/games/mega-1.png'
import mega2 from '../assets/images/games/mega-2.png'
import mega3 from '../assets/images/games/mega-3.png'
import mega4 from '../assets/images/games/mega-4.png'
import mega5 from '../assets/images/games/mega-5.png'
import mega6 from '../assets/images/games/mega-6.png'
import banker from '../assets/images/games/banker-1.png'
import Footer from '../components/Footer'
import { useNavigate } from 'react-router-dom'

const GameDynamics = () => {
  const navigate = useNavigate()
  const [currentTab, setCurrentTab] = useState(1)

  const megas = [
    {
      path: mega1,
      description: `
      Pick 1 number to match the first number in the daily draw
      `
    },
    {
      path: mega2,
      description: `
      Pick 2 numbers to match any 2 numbers in the daily draw
      `
    },
    {
      path: mega3,
      description: `
      Pick 3 numbers to match any 3 numbers in the daily draw
      `
    },
    {
      path: mega4,
      description: `
      Pick 4 numbers to match any 4 numbers in the daily draw
      `
    },
    {
      path: mega5,
      description: `
      Pick 5 numbers to match any 5 numbers in the daily draw
      `
    },
    {
      path: mega6,
      description: `
      Pick 6 numbers to match any 6 numbers in the daily draw
      `
    }
  ]

  const perms = [
    {
      path: perm2,
      description: `
      Pick 2 or more numbers to combine in sets of 2. If any of your combinations appear in the daily draw, you win!
      `
    },
    {
      path: perm3,
      description: `
      Pick 3 or more numbers to combine in sets of 3. If any of your combinations appear in the daily draw, you win!
      `
    },
    {
      path: perm4,
      description: `
      Pick 4 or more numbers to combine in sets of 4. If any of your combinations appear in the daily draw, you win!
      `
    },
    {
      path: perm5,
      description: `
      Pick 5 or more numbers to combine in sets of 5. If any of your combinations appear in the daily draw, you win!
      `
    },
    {
      path: perm6,
      description: `
      Pick 6 or more numbers to combine in sets of 6. If any of your combinations appear in the daily draw, you win! 
      `
    }
  ]

  const bankerGame = {
    path: banker,
    description: `
    Pick 1 number to match any number in the daily draw 
    `
  }

  return (
    <div className='flex flex-col'>
      <div
        className='bg-cover w-full flex justify-center items-center bg-center h-[280px] bg-no-repeat pt-16'
        style={{ backgroundImage: `url(${banner})` }}
      >
        <h1 className='font-tommy text-[3rem] text-[#FFFFFF]'>
          GAMES DYNAMICS
        </h1>
      </div>
      <div className='flex gap-12 py-6 mb-6 items-center justify-center uppercase font-tommy text-[#331700] bg-[#FFF1E6]'>
        <span
          onClick={e => {
            e.preventDefault()
            setCurrentTab(1)
          }}
          className={
            currentTab === 1
              ? 'border-b-2 border-[#FF7300] cursor-pointer'
              : 'cursor-pointer'
          }
        >
          Mega&nbsp;1&nbsp;-&nbsp;6
        </span>
        <span
          onClick={e => {
            e.preventDefault()
            setCurrentTab(2)
          }}
          className={
            currentTab === 2
              ? 'border-b-2 border-[#FF7300] cursor-pointer'
              : 'cursor-pointer'
          }
        >
          Perm&nbsp;2&nbsp;-&nbsp;6
        </span>
        <span
          onClick={e => {
            e.preventDefault()
            setCurrentTab(3)
          }}
          className={
            currentTab === 3
              ? 'border-b-2 border-[#FF7300] cursor-pointer'
              : 'cursor-pointer'
          }
        >
          Banker
        </span>
      </div>
      <div className='flex flex-col justify-center items-center'>
        {currentTab === 1 ? (
          <h1 className='text-[#662E00] font-tommy my-6 lg:text-4xl md:text-3xl text-xl uppercase'>
            How&nbsp;to&nbsp;play&nbsp;Mega&nbsp;1&nbsp;-&nbsp;6{' '}
          </h1>
        ) : currentTab === 2 ? (
          <h1 className='text-[#662E00] font-tommy my-6 lg:text-4xl md:text-3xl text-xl uppercase'>
            How&nbsp;to&nbsp;play&nbsp;Perm&nbsp;2&nbsp;-&nbsp;6{' '}
          </h1>
        ) : (
          <h1 className='text-[#662E00] font-tommy my-6 lg:text-4xl md:text-3xl text-xl uppercase'>
            How&nbsp;to&nbsp;play&nbsp;Banker{' '}
          </h1>
        )}
      </div>
      {currentTab === 3 ? (
        <div className='flex flex-col justify-center items-center mb-5'>
          <div
            style={{ backgroundImage: `url(${bankerGame.path})` }}
            className='w-[18.4rem] h-[11.4rem] mb-3'
          ></div>
          <p className='flex w-[18.4rem] lg:text-xl md:text-lg text-base font-normal leading-normal text=[#343332]'>
            {bankerGame.description}
          </p>
        </div>
      ) : (
        <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 xl:px-96 md:px-44 px-24 place-items-center mb-10'>
          {currentTab === 1
            ? megas.map((mega, key) => (
                <>
                  <div key={key} className='flex flex-col items-center'>
                    <div
                      style={{ backgroundImage: `url(${mega.path})` }}
                      className='w-[18.4rem] h-[11.4rem] mb-3'
                    ></div>
                    <p className='flex lg:px-9 md:px-24 lg:text-xl md:text-lg text-base font-normal leading-normal text=[#343332]'>
                      {mega.description}
                    </p>
                  </div>
                </>
              ))
            : currentTab === 2
            ? perms.map((perm, key) => (
                <>
                  <div key={key} className='flex flex-col items-center'>
                    <div
                      style={{ backgroundImage: `url(${perm.path})` }}
                      className='w-[18.4rem] h-[11.4rem] mb-3'
                    ></div>
                    <p className='flex lg:px-9 md:px-24 lg:text-xl md:text-lg text-base font-normal leading-normal text=[#343332]'>
                      {perm.description}
                    </p>
                  </div>
                </>
              ))
            : null}
        </div>
      )}
      <div className='flex flex-col md:flex-row pb-4 lg:px-96 md:px-16  items-center justify-between uppercase font-tommy text-[#331700] bg-[#FFF1E6]'>
        <h1 className='font-tommy my-6 lg:text-4xl md:text-3xl text-xl uppercase'>
          play now
        </h1>
        <div className='flex gap-4'>
          <button
            onClick={e => {
              e.preventDefault()
              navigate('/sign-up')
            }}
            type='button'
            // disabled={!isValid || pending}
            className='hover:bg-[#FF4100] uppercase py-2 transition-all hover:text-white  bg-[#FF7300] text-white px-8 rounded-3xl font-bold '
          >
            web
          </button>
          <a
            rel='noreferrer'
            href={
              'https://play.google.com/store/apps/details?id=com.megafortunelottery.sponzio.customer&pli=1'
            }
            target='_blank'
          >
            <button
              type='button'
              // disabled={!isValid || pending}
              className='hover:bg-[#FF4100] uppercase py-2 transition-all hover:text-white  bg-[#FF7300] text-white px-8 rounded-3xl font-bold '
            >
              android
            </button>
          </a>
          {/* <button
            type='button'
            // disabled={!isValid || pending}
            className='hover:bg-[#FF4100] uppercase py-2 transition-all hover:text-white  bg-[#FF7300] text-white px-8 rounded-3xl font-bold '
          >
            ios
          </button> */}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default GameDynamics
