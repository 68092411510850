import React from 'react'
import notFoundImage from '../assets/images/404 Illustration.svg'
import { useNavigate } from 'react-router-dom'


const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className='flex flex-col items-center justify-center'>
      <div className='md:mt-72 mt-52 mb-10'>
        <img className='h-16 md:h-32' src={notFoundImage} alt='Page Not Found' />
      </div>
      <div>
        <h2 className='lg:text-[49px] md:text-[39px] text-2xl mb-4 font-tommy text-[#343332] text-center'>
          This page isn't available
        </h2>
        <p className='text-[#343332] text-center md:text-xl text-base mb-14 px-3'>
          You may have followed an old link, or the page may have been moved.
        </p>
      </div>
      <div>
        <button
          // disabled={!isValid || pending}
          className='hover:bg-[#FF4100] py-2 transition-all hover:text-white  bg-[#FF7300] text-white  px-28 rounded-3xl font-bold mx-3'
          onClick={() => {
            navigate('/')
          }}
        >
          Back to Home
        </button>
      </div>
    </div>
  )
}

export default NotFound
