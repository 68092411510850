import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "../custom.css";
import { Link } from "react-router-dom";
import { ArrowDownCircle } from "react-feather";
import bg from "../assets/images/Jackpot_2.jpg";
import bg2 from "../assets/images/Jackpot.jpg";

const Slider = () => {
  return (
    <div className=" h-screen relative bg-contain">
      <Swiper
        navigation={true}
        autoplay={{
          delay: 9000,
          disableOnInteraction: false,
          // pauseOnMouseEnter: true,
        }}
        modules={[Navigation, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="relative h-screen">
            <div
              className="absolute inset-0 bg-cover "
              style={{ backgroundImage: `url(${bg2})` }}
            ></div>
            <div className=" absolute inset-0 bg-black opacity-20"></div>
            <div className="absolute inset-0 h-full w-full">
              <div className="flex min-h-full w-12/12 px-24 items-center justify-end">
                <div className="space-y-6 ">
                  <div className="">
                    <div className="text-white ">
                      <p className="text-4xl font-tommy opacity-90 md:text-6xl capitalize mb-4 ">
                        INSTANT WINS <br /> WITH AS LITTLE <br /> AS GHS 1!
                      </p>
                    </div>

                    <div className="pt-5 ">
                      <Link to="/login">
                        <button className=" font-tommy bg-[#FF7300] text-white py-3 px-10 font-bold uppercase rounded-3xl">
                          Play Now
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" absolute bottom-0 right-1/2 pb-4 animate-bounce">
                <ArrowDownCircle color="white" size={35} />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative h-screen">
            <div
              className="absolute inset-0 bg-cover "
              style={{ backgroundImage: `url(${bg})` }}
            ></div>
            <div className=" absolute inset-0 bg-black opacity-20"></div>
            <div className="absolute inset-0 h-full w-full">
              <div className="flex min-h-full w-full  justify-end py-60 mt-24  px-20">
                <div>
                  <div className="text-white">
                    <p className="text-4xl font-tommy opacity-90 md:text-6xl uppercase font-extrabold mb-4 ">
                      Mega Stakes <br />
                    </p>
                    <p className="text-4xl font-tommy opacity-90 uppercase md:text-6xl  font-extrabold mb-4 ">
                      Mega Wins
                    </p>
                  </div>
                  <div className="pt-5">
                    <Link to="/login">
                      <button className=" font-tommy bg-[#FF7300] text-white py-3 px-10 font-bold uppercase rounded-3xl">
                        Play Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className=" absolute bottom-0 right-1/2 pb-4 animate-bounce">
                <ArrowDownCircle color="white" size={35} />
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Slider;
