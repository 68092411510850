import React from 'react'
import bg from '../assets/images/Vector.svg'
import logo from '../assets/images/logos/Mega Fortune Lottery Logo Dark.png'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Support = () => {
  const navigate = useNavigate()

  return (
    <div
      className='h-screen object-cover text-[#1A1919] bg-cover flex flex-col items-center py-10'
      style={{ backgroundImage: `url(${bg})` }}
    >
      {process.env.REACT_APP_ENVIRONMENT_NAME === 'PRODUCTION' ? (
        <Helmet>
          <title>Support</title>
          <meta
            name='description'
            content='Support Page for Mega Fortune Lottery'
          />
        </Helmet>
      ) : null}

      <div className='text-secondary-20 mt-10 p-10 relative left-24 md:left-0 flex flex-row justify-center'>
        <div className='h-16 w-52 border-black p-5'>
          <img className='object-contain' src={logo} alt='Mega Fortune Lottery Logo' />
        </div>
        <div className='p-5 border-l-4 border-black text-center text-5xl font-tommy'>
          <p>Support</p>
        </div>
      </div>
      <div className=' mt-6 text-[32px] relative left-24 md:left-0  font-sans font-semibold'>
        <p>How can we help?</p>
      </div>
      <div className='flex flex-row relative left-[70px] md:left-0 gap-5 mt-10'>
        <div
          onClick={e => {
            e.preventDefault()
            navigate('/faqs')
          }}
          className='flex border-2 border-black justify-center items-center md:h-[274px] lg:w-[400px] h-[180px] w-[200px] md:w-[300px] md:text-[40px] text-[30px] font-sans font-bold rounded-3xl cursor-pointer'
        >
          FAQs
        </div>
        <div
          onClick={e => {
            e.preventDefault()
            navigate('/how-to-play')
          }}
          className='flex border-2 border-black justify-center items-center md:h-[274px] lg:w-[400px] md:w-[300px] h-[180px] w-[200px] md:text-[40px] text-[30px] font-sans font-bold rounded-3xl cursor-pointer'
        >
          How to Play
        </div>
      </div>

      <div className='flex items-center relative left-24 md:left-0 mt-10 text-4xl font-tommy text-[#343332]'>
        Need more help?
      </div>
      {/* <div className='mt-8 relative left-24 md:left-0'>
        <button
          onClick={e => {
            e.preventDefault()
            navigate('/contact')
          }}
          className='bg-gradient-to-b from-[#FFAC00] to-[#FF4100] px-12 py-5 text-white text-2xl rounded-[48px]'
        >
          Contact Support
        </button>
      </div> */}
    </div>
  )
}

export default Support
