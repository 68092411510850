import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/logos/Mega Fortune Logo Round.png'
import { motion } from 'framer-motion'
import bg from '../assets/images/Mega Stakes.jpg'
// import bg2 from "../assets/images/win.jpg";
import { Helmet } from 'react-helmet'

const AboutUs = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {process.env.REACT_APP_ENVIRONMENT_NAME === 'PRODUCTION' ? (
        <Helmet>
          <title>About Us</title>
          <meta
            name='description'
            content='What Mega Fortune Lottery is about'
          />
        </Helmet>
      ) : null}

      <div className=' relative  pb-32'>
        <div className='md:h-screen'>
          <img
            className='  w-full md:object-cover object-contain'
            src={bg}
            alt='Mega Stakes '
          />
        </div>
        <div className='flex justify-center md:items-center md:mt-56'>
          <div className='mx-auto absolute max-w-7xl bg-gray-50 rounded-xl pt-12 pb-16 md:-mt-40 space-y-8 px-4 sm:px-6 lg:px-8'>
            <div className='mx-auto max-w-prose text-base lg:max-w-none'>
              <h2 className='text-lg capitalize font-semibold  font-tommy text-[#FF7300]'>
                Get to know more about us
              </h2>
              <p className='mt-2 capitalize text-4xl font-tommy leading-8 tracking-tight text-gray-900 sm:text-4xl'>
                What makes us different
              </p>
            </div>

            <div className='lg:grid lg:grid-cols-2 lg:items-start lg:gap-8'>
              <div className='relative z-10'>
                <div className=' mx-auto  lg:max-w-none'>
                  <p>
                    Founded in 2022, Mega Fortune is the newest and boldest
                    player in Ghana’s thriving Lottery Industry. Licensed by the
                    National Lottery Authority (NLA), our mission is to bring
                    good fortune, change lives and responsibly provide the
                    thrill of lottery entertainment.
                  </p>
                  <br />
                  <p>
                    We enable ambitious people to unlock opportunities for big
                    wins with our wide range of games.
                  </p>
                  <br />
                  <p>
                    With our trusted agents and online platforms, you can play
                    Live Lotteries (Online Rush), Draw Lotteries, Ticket
                    Lotteries, and Event Lotteries 24/7 at your convenience.
                  </p>
                  <br />
                  <p>
                    You can play Live Lotteries (Online Rush), Draw Lotteries,
                    Ticket Lotteries, Instant (Scratch) Tickets, and Event
                    Lotteries.
                  </p>
                  <br />
                </div>
                <div className='mx-auto mt-10 flex max-w-prose text-base lg:max-w-none'>
                  {/* <div className='rounded-md shadow'>
                    <Link to='/contact'>
                      <p className='flex w-full items-center justify-center rounded-md border border-transparent bg-[#FF7300] px-5 py-3 text-base font-medium text-white hover:shadow-lg'>
                        Get In Touch With Us
                      </p>
                    </Link>
                  </div> */}
                </div>
              </div>
              <div className='relative mx-auto mt-12 max-w-prose text-base lg:mt-0 lg:max-w-none'>
                <svg
                  className='absolute top-0 right-0 -mt-20 lg:-mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20'
                  width={404}
                  height={384}
                  fill='none'
                  viewBox='0 0 404 384'
                  aria-hidden='true'
                >
                  <defs>
                    <pattern
                      id='bedc54bc-7371-44a2-a2bc-dc68d819ae60'
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className='text-[#FF4100]'
                        fill='currentColor'
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill='url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)'
                  />
                </svg>
                <blockquote className='relative rounded-lg bg-white shadow-lg'>
                  <div className='rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8'>
                    {/* <img src={logo2} alt="Workcation" className="h-12" /> */}
                    <div className='relative mt-8 text-lg font-medium text-gray-700'>
                      <svg
                        className='absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-gray-200'
                        fill='currentColor'
                        viewBox='0 0 32 32'
                        aria-hidden='true'
                      >
                        <path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z' />
                      </svg>
                      <p className='relative'>
                        <p>
                          Our headquarters in Accra and offices across Ghana
                          ensure we remain accessible to our players and the
                          lottery community at large. Join us today and
                          experience a world of wins and excitement with Mega
                          Fortune.
                        </p>
                      </p>
                    </div>
                  </div>
                  <cite className='relative flex items-center rounded-b-lg bg-[#FF7300] py-5 px-6 not-italic sm:mt-10 sm:items-start sm:py-5 sm:pl-12 sm:pr-10'>
                    <span className='relative rounded-full border-2 border-white sm:absolute sm:top-0 sm:-translate-y-1/2 sm:transform'>
                      <img
                        className='h-12 w-12 rounded-full bg-indigo-300 sm:h-20 sm:w-20'
                        src={logo}
                        alt='Mega Fortune Logo Round'
                      />
                    </span>
                    <span className='relative ml-4 font-semibold  font-tommy leading-6 text-black sm:ml-24 sm:pl-1'>
                      <span className='font-semibold  font-tommy  text-white sm:inline'>
                        Mega Fortune Games
                      </span>
                    </span>
                  </cite>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default AboutUs
