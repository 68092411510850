import React from 'react'
import Footer from '../components/Footer'
import { NavLink, useNavigate } from 'react-router-dom'

const GamesHome = () => {
  const navigate = useNavigate()

  return (
    <div className='flex flex-col'>
      <div className='flex h-[17.5rem] items-center pt-16 justify-center bg-gradient-to-b from-[#FF4100] to-[#FFAC00]'>
      <h1 className='font-tommy text-[3rem] text-[#FFFFFF]'> GAMES</h1>
      </div>
      <div className='flex md:flex-row md:px-0 flex-col px-10 items-center justify-center gap-4 py-20'>
        <NavLink
          onClick={e => {
            e.preventDefault()
            navigate('/daily-games')
          }}
          className='lg:w-[25rem] lg:h-[16.5rem] w-[20rem] h-[8rem] flex justify-center items-center text-4xl hover:text-[#FF7300] border-2 border-[#000000] font-tommy rounded-3xl'
        >
          DAILY GAMES
        </NavLink>
        <NavLink
          onClick={e => {
            e.preventDefault()
            navigate('/game-dynamics')
          }}
          className='lg:w-[25rem] lg:h-[16.5rem] w-[20rem] h-[8rem] flex justify-center items-center text-4xl hover:text-[#FF7300] border-2 border-[#000000] font-tommy rounded-3xl'
        >
          GAME DYNAMICS
        </NavLink>
        <NavLink
          onClick={e => {
            e.preventDefault()
            navigate('/game-pricing')
          }}
          className='lg:w-[25rem] lg:h-[16.5rem] w-[20rem] h-[8rem] flex justify-center items-center text-4xl hover:text-[#FF7300] border-2 border-[#000000] font-tommy rounded-3xl'
        >
          GAME PRICING
        </NavLink>
      </div>
      <Footer />
    </div>
  )
}

export default GamesHome
