import React from 'react'
import PricingTable from '../components/PricingTable'
import Footer from '../components/Footer'

const GamesPricing = () => {
  const games = [
    {
      gameName: 'Megas',
      gameTypes: [
        {
          stakeCost: 'GHC 1',
          gameType: 'Mega 1',
          multiplier: 'x40',
          winning: 'GHC 40'
        },
        {
          stakeCost: 'GHC 1',
          gameType: 'Mega 2',
          multiplier: 'x240',
          winning: 'GHC 240'
        },
        {
          stakeCost: 'GHC 1',
          gameType: 'Mega 3',
          multiplier: 'x2100',
          winning: 'GHC 2,100'
        },
        {
          stakeCost: 'GHC 1',
          gameType: 'Mega 4',
          multiplier: 'x6000',
          winning: 'GHC 6,000'
        },
        {
          stakeCost: 'GHC 1',
          gameType: 'Mega 5',
          multiplier: 'x44000',
          winning: 'GHC 44,000'
        },
        {
          stakeCost: 'GHC 1',
          gameType: 'Mega 6',
          multiplier: 'x50000',
          winning: 'GHC 50000'
        }
      ]
    },
    {
      gameName: 'Perms',
      gameTypes: [
        {
          stakeCost: 'GHC 1',
          gameType: 'Perm 2',
          multiplier: 'x240',
          winning: 'GHC 240'
        },
        {
          stakeCost: 'GHC 1',
          gameType: 'Perm 3',
          multiplier: 'x2100',
          winning: 'GHC 2,100'
        },
        {
          stakeCost: 'GHC 1',
          gameType: 'Perm 4',
          multiplier: 'x6000',
          winning: 'GHC 6,000'
        },
        {
          stakeCost: 'GHC 1',
          gameType: 'Perm 5',
          multiplier: 'x44000',
          winning: 'GHC 44,000'
        },
        {
          stakeCost: 'GHC 1',
          gameType: 'Perm 6',
          multiplier: 'x50000',
          winning: 'GHC 50000'
        }
      ]
    },
    {
      gameName: 'Banker (3y3 Mega)',
      gameTypes: [
        {
          stakeCost: 'GHC 1',
          gameType: 'Banker',
          multiplier: 'x1200',
          winning: 'GHC 1200'
        }
      ]
    }
  ]

  return (
    <div className='flex flex-col'>
      <div className='flex h-[17.5rem] items-center pt-16 justify-center bg-gradient-to-b from-[#FF4100] to-[#FFAC00]'>
        <h1 className='font-tommy text-[3rem] text-[#FFFFFF]'>GAME PRICING</h1>
      </div>
      <div className='flex flex-col md:px-60 px-1'>
        {games.map(game => (
          <PricingTable title={game.gameName} games={game.gameTypes} />
        ))}
      </div>
      <Footer />
    </div>
  )
}

export default GamesPricing
