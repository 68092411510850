import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import logo2 from '../assets/images/logos/Mega Fortune Lottery Logo Dark.png'
import { GrAppleAppStore, GrGooglePlay } from "react-icons/gr";

const Modal = ({ isOpen, setIsOpen }) => {
  const closeModal = () => {
    setIsOpen(false)
  }


  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-2xl -mt-96 transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900 flex flex-row justify-center items-center'
                  >
                    <div className='bg-white rounded-full w-40 border-4 p-6 m-3 border-[#FF7300]/60'>
                      <img className='h-10' src={logo2} alt='Mega Fortune Lottery Logo' />
                    </div>
                  </Dialog.Title>
                  <div className='mt-2'>
                    <div className='py-6 text-[#043060] bg-white shadow-lg '>
                      <div className=' flex  w-10/12 mx-auto  '>
                        <div className='p-6 w-10/12  mx-auto flex flex-col items-center  '>
                          <p className='font-tommy flex flex-row items-center'>
                            Visit the <GrAppleAppStore size={50} className=' ml-3' />pp or <GrGooglePlay size={50} className=' text-[#043060] ml-3' />laystore
                          </p>
                          <p className='mt-5'> to download the Mega Fortune Agent App and log in</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default Modal
