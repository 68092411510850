import React from 'react'
// import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom'
import { HiOutlineLogout } from 'react-icons/hi'

const LogoutButton = () => {
  // const { isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate()
 return localStorage.getItem("token") ? (
    <button
      className='w-full text-start py-6 flex flex-row items-center '
      onClick={() => {
        // logout({ returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL });
        navigate('/')
        localStorage.clear()
      }}
    >
      <HiOutlineLogout className='mr-3' />
      <span>Log&nbsp;out</span>
    </button>
  ) : null
}

export default LogoutButton
