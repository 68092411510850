import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import logo2 from '../assets/images/logos/Mega Fortune Lottery Logo Dark.png'
import { FiX } from 'react-icons/fi'


function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const PhoneNumberModal = ({
  isOpen,
  setIsOpen,
  setPayingNumber, 
  payingNumber,
  submitMethod
}) => {
  const closeModal = () => {
    setIsOpen(false)
  }

  let currentPhone = "0" + payingNumber.substring(3);

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-2xl -mt-96 transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900 flex flex-col'
                  >
                    <div className='flex justify-start items-start p-2'>
                      <FiX
                        onClick={e => {
                          e.preventDefault()
                          closeModal()
                        }}
                      />
                    </div>
                    <div className='flex flex-col items-center justify-center'>
                      <div className='bg-white rounded-full w-40 border-4 p-6 m-3 border-[#FF7300]/60'>
                        <img className='h-10' src={logo2} alt='Mega Fortune Lottery Logo' />
                      </div>
                      <div className='mb-3 lg:px-0 px-6 text-center'>
                        <h2>Enter the Phone number you want to pay with?</h2>
                      </div>
                    </div>
                  </Dialog.Title>
                  <div className='mt-2 px-20 '>
                    <form>
                      <div className='flex items-center pl-6 border-2 rounded-3xl border-[#FF4100] mb-4 '>
                        <label
                          className=' w-1/3 text-xs font-semibold'
                          htmlFor=''
                        >
                          Paying Number
                        </label>
                        <input
                          type='text'
                          // name='otp'
                          // value={currentPhone}
                          defaultValue={currentPhone}
                          onChange={e => {
                            e.preventDefault()
                            setPayingNumber(e.target.value)
                          }}
                          className={classNames(
                            'w-full focus:outline-none bg-transparent py-2 pr-6'
                          )}
                          required
                        />
                      </div>
                      <div className=' flex justify-center mb-6 '>
                        <button
                          onClick={e => {
                            e.preventDefault()
                            submitMethod()
                          }}
                          // type='submit'
                          // disabled={!isValid || pending}
                          className='hover:bg-[#FF4100] py-2 transition-all hover:text-white  bg-[#FF7300] text-white lg:px-8 px-4 rounded-3xl font-bold mx-3'
                        >
                          Confirm and Pay
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default PhoneNumberModal
