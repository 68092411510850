import React from 'react'
import { Helmet } from 'react-helmet'

const TermsAndConditions = () => {
  return (
    <div>
      {process.env.REACT_APP_ENVIRONMENT_NAME === 'PRODUCTION' ? (
        <Helmet>
          <title>Terms and Conditions</title>
          <meta
            name='description'
            content='Terms and Conditions for the usage of the Mega Fortune Lottery Platform'
          />
        </Helmet>
      ) : null}

      <div className=' '>
        <div className=' bg-primary-100 text-secondary-200 bg-gray-50 py-24'>
          <div className=' text-center uppercase text-5xl pt-12  font-bold'>
            <p>Terms & Conditions</p>
          </div>
        </div>
        <div className='mx-auto max-w-7xl pb-16 px-4  sm:px-6 lg:px-8'>
          <div className='mx-auto max-w-5xl divide-y-2 divide-gray-200'>
            <div className='space-y-4'>
              <h1 className=' text-3xl font-semibold'>INTRODUCTION</h1>
              <p>
                Please read the following Terms and Conditions carefully as they
                are the legal terms you agree to abide by when you access and
                open an account to play our lottery games. We strongly recommend
                that you print a copy of these Terms and Conditions for your
                records. 
              </p>
              <p>
                These interactive terms and conditions (the "Terms and
                Conditions") set out the various rules and procedures that you
                will need to follow to access and use our lottery platforms and
                to open and maintain your Player account. These Terms and
                Conditions also provide information on staking games. The game
                rules are available on our website at
                <a className=' text-blue-600' href='www.megafortunelottery.com'>
                  {' '}
                  www.megafortunelottery.com
                </a>
              </p>
              <p>
                Our lottery games can be accessed through our website, App and
                USSD short code (collectively called Mega Fortune Lottery
                Platforms). This gaming platform is owned and operated by
                Fortune Synergy.  
              </p>
              <p>
                Mega Fortune Lottery is licensed by the National Lottery
                Authority (NLA) of Ghana.
              </p>
              <p>
                You must be at least eighteen years old, legally resident, and
                physically located in Ghana to play the Games.
              </p>
              <p>
                In choosing to purchase a game, the Player must choose a
                particular game from the list of games available on the Website,
                USSD or App. Available Game is indicated and a Player must
                choose from those available. 
              </p>
              <p>
                After a draw, the Player is informed via a notification alert if
                he/she is a winner and how much he/she has won.
              </p>

              <h3 className=' text-xl font-semibold uppercase'>Pay-outs</h3>
              <p>
                All payouts arising from the play of Mega Fortune Lottery games
                shall be done electronically through the Mobile Wallets of
                Players. However, the Players acknowledge that we are at the
                mercy of other Payment Service Providers; in this regard, Mega
                Fortune Lottery shall not be held liable for any delays in the
                electronic transfer of the Payouts.
              </p>
              <p>
                Mega Fortune Lottery reserves the right to carry out additional
                verification procedures for payouts exceeding GHS 5,000 or when
                there is a suspicion of fraud.
              </p>
              <p>
                Mega Fortune Lottery reserves the right to limit or to refuse a
                Player from playing any of its lotto/lottery games.
              </p>

              <h1 className='text-3xl font-semibold'>
                FOR REGISTERED AND UNREGISTERED USERS
              </h1>
              <h2 className='text-xl font-semibold uppercase'>
                Unregistered Users
              </h2>
              <p>
                If you simply want to browse around our website, there is no
                need to register with Us. However, by accessing and using our
                website, you agree to read, understand and agree to the Terms
                and Conditions. If you do not agree to accept and abide by these
                Terms and Conditions, you should not use our website and by
                extension, any of our gaming platforms. 
              </p>

              <h3 className='text-xl font-semibold uppercase'>
                Registered Users
              </h3>
              <p>
                When registering as a Player, you will be asked to confirm if
                you have read and agree to the Terms and Conditions set out
                applying to registered users. If you do not agree to accept and
                agree to these Terms and Conditions, you should not use any of
                our gaming platforms.
              </p>

              <h3>Use of our lottery platforms</h3>
              <div className=' space-y-3'>
                <p>
                  All Mega Fortune Lottery Platforms are controlled and operated
                  by Fortune Synergy. The information contained therein is
                  solely for the use of players and prospective players. Please
                  note: For the full suite of available MFL Games, please visit
                  www.megafortunelottery.com.
                </p>

                <p>
                  In accessing our lottery platforms, you do so at your own risk
                  and on your initiative and are responsible for compliance with
                  all applicable laws. Use of our lottery platforms and/or
                  purchase of any games offered unlawfully constitutes a breach
                  of these Terms and Conditions and may result in, amongst other
                  penalties, the immediate termination of your Account and your
                  right to use our website.
                </p>
                <p>
                  All use by you of our lottery platforms is at your own risk.
                  You assume complete responsibility for, and for all risk of
                  loss resulting from, downloading and/or using of, or referring
                  to, or relying on the content of or any other information
                  obtained from your use. You agree that MFL will not be liable
                  for damages arising out of your use or your inability to use
                  our lottery platforms, and you hereby waive all claims with
                  respect thereto, whether based on contract, tort, or other
                  grounds.
                </p>
                <p>
                  You agree not to use this Website to engage in any unlawful
                  activities including without limitation to activities which:
                </p>
                <p>
                  (a) infringe the proprietary or intellectual property rights
                  of MFL and/or its licensors and/or third parties;
                </p>
                <p>(b) compromise the privacy of others;</p>
                <p>(c) are immoral, illegal, or advocating illegal activity;</p>
                <p>
                  (d) destroy the integrity of any data or other computer-based
                  information;
                </p>
                <p>
                  (e) violate local, state, or national laws of any country;
                </p>
                <p>
                  (f) reveal trade secrets, unless you own them or have the
                  permission of the copyright owner;
                </p>
                <p>
                  (g) infringe on any privacy or publicity rights of others;
                </p>
                <p>(h) impersonate another person.</p>
                <p>You also agree not to;</p>
                <p>
                  (i) interfere with or disrupt (or attempt to interfere with or
                  disrupt) our lottery platforms or servers or networks
                  connected to same, or to disobey any requirements, procedures,
                  policies or regulations of networks connected to our lottery
                  platforms;
                </p>
                <p>
                  (j) provide any information to MFL that is false or
                  misleading, that attempts to hide your identity or that you do
                  not have the right to disclose;
                </p>
                <p>
                  (k) use our lottery platforms in any manner which could
                  damage, disable, overburden or impair same while interfering
                  with any other party’s use and/or enjoyment of our lottery
                  platforms;
                </p>
                <p>
                  (l) attempt to gain unauthorized access to any account(s),
                  computer systems and networks connected to any MFL lottery
                  platforms, its facilities and/or services through hacking,
                  password mining or any other means
                </p>
                <p>
                  (m) use our lottery platforms to impersonate any person or
                  entity or misrepresent your affiliation with a person or
                  entity
                </p>
                <p>
                  You hereby agree to indemnify and hold harmless, MFL against
                  any claims, proceedings and actions taken and all damages
                  (including costs) that may be awarded or agreed to be paid to
                  any third party in respect of any claim or action arising from
                  your breach of this condition or any of the items listed.
                </p>

                <h3 className='text-xl font-semibold uppercase'>Disclaimers</h3>
                <p>
                  OUR LOTTERY PLATFORMS ARE AVAILABLE TO ALL USERS “AS IS” AND,
                  TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW, ALL MFL
                  GAMING PLATFORMS ARE MADE AVAILABLE WITHOUT ANY
                  REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR
                  IMPLIED.  MFL MAKES NO REPRESENTATIONS, WARRANTIES OR
                  UNDERTAKINGS THAT OUR LOTTERY PLATFORMS, OR THE SERVER THAT
                  MAKES IT AVAILABLE, WILL BE FREE FROM DEFECTS, INCLUDING, BUT
                  NOT LIMITED TO VIRUSES OR OTHER HARMFUL ELEMENTS.  MFL ACCEPTS
                  NO LIABILITY FOR ANY INFECTION BY COMPUTER VIRUS, BUG,
                  TAMPERING, UNAUTHORISED ACCESS, INTERVENTION, ALTERATION OR
                  USE, FRAUD, THEFT, TECHNICAL FAILURE, ERROR, OMISSION,
                  INTERRUPTION, DELETION, DEFECT, DELAY, OR ANY EVENT OR
                  OCCURRENCE BEYOND THE CONTROL OF MFL, WHICH CORRUPTS OR
                  AFFECTS THE ADMINISTRATION, SECURITY, FAIRNESS AND THE
                  INTEGRITY OR PROPER CONDUCT OF ANY ASPECT OF OUR LOTTERY
                  PLATFORMS.  MFL MAKES NO REPRESENTATIONS, WARRANTIES OR
                  UNDERTAKINGS ABOUT THE SERVICE, FACILITIES AND/OR MATERIALS ON
                  OUR LOTTERY PLATFORMS (INCLUDING WITHOUT LIMITATION, THEIR
                  ACCURACY, COMPLETENESS OR THEIR MERCHANTABILITY, QUALITY OR
                  FITNESS FOR A PARTICULAR PURPOSE)
                </p>
                <p>
                  YOU AGREE THAT THE DOWNLOAD OR UPLOAD OF ANY MATERIAL THROUGH
                  OUR LOTTERY PLATFORMS IS PERFORMED AT YOUR DISCRETION AND RISK
                  AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY LOSS OR DAMAGE
                  TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT MAY RESULT IN THE
                  DOWNLOAD OR UPLOAD OF ANY MATERIAL.
                </p>
                <p>
                  ALL USE BY YOU OF OUR LOTTERY PLATFORMS IS AT YOUR OWN RISK.
                  YOU ASSUME COMPLETE RESPONSIBILITY FOR, AND FOR ALL RISK OF
                  LOSS RESULTING FROM, DOWNLOADING AND/OR USING OF, REFERRING TO
                  OR RELYING ON THE FACILITIES OR MATERIALS PROVIDED ON OUR
                  LOTTERY PLATFORMS. YOU AGREE THAT MFL AND PROVIDERS OF
                  TELECOMMUNICATIONS AND NETWORK SERVICES TO MFL WILL NOT BE
                  LIABLE FOR DAMAGES ARISING OUT OF YOUR USE OR YOUR INABILITY
                  TO USE OUR LOTTERY PLATFORMS, AND YOU HEREBY WAIVE ANY AND ALL
                  CLAIMS WITH RESPECT THERETO, WHETHER BASED ON CONTRACT, OR
                  OTHER GROUNDS.
                </p>

                <h3 className='text-xl font-semibold uppercase'>
                  Limitation Of Liability
                </h3>
                <p>
                  To the maximum extent permitted by applicable law, neither MFL
                  nor any of its officers, directors, employees, agents,
                  affiliates or other representatives will be liable for loss or
                  damages arising out of or in connection with your use of our
                  lottery platforms including, but not limited to, direct,
                  indirect, incidental, special or consequential loss or
                  damages, loss of data, loss of income, profit or opportunity,
                  loss of or damage to property and claims of third parties,
                  even if MFL has been advised of the possibility of such loss
                  or damages, or such loss or damages were reasonably
                  foreseeable. In no event shall MFL nor any of its officers,
                  directors, agents, employees, affiliates, or other
                  representatives be liable for any loss or damages whatsoever
                  resulting from the statements or conduct of any third party or
                  the interruption, suspension, or termination of our lottery
                  platforms, whether such interruption, suspension or
                  termination was justified or not, negligent or intentional,
                  inadvertent or advertent.
                </p>
                <p>
                  Without limiting the foregoing, under no circumstances shall
                  MFL or any of its officers, directors, agents, employees,
                  affiliates or other representatives be held liable for any
                  delay or failure in performance resulting directly or
                  indirectly from acts of nature, forces or causes beyond its
                  reasonable control, including, without limitation, internet
                  failure, computer equipment failures, telecommunication
                  failures, other equipment failures, electrical power failures,
                  strikes, riots, interactions, civil disturbances, shortages of
                  labour or materials, fires, storms, explosions, force majeure,
                  war, governmental actions, orders of domestic or foreign
                  courts or tribunals, delays, losses, errors or omissions made
                  by the postal or other delivery services or the banking
                  system, or the non-performance of a third party.
                </p>
                <p>
                  MFL does not exclude liability for fraud, death, or personal
                  injury caused by our negligence (or that of our employees or
                  authorized representatives).
                </p>
                <p>
                  In any event, your sole remedy under these Terms and
                  Conditions will be the reimbursement to you of the monies paid
                  by you in respect of the relevant game.
                </p>
                <h3 className='text-xl font-semibold uppercase'>Indemnity</h3>
                <p>
                  You agree to defend, indemnify and hold MFL (and as
                  applicable, its officers, directors, employees, agents,
                  licensors, subsidiaries, affiliates and any of our third-party
                  information service providers) harmless against any demands,
                  losses, expenses, damages and costs, including legal costs,
                  and claims made by or liabilities to any third party resulting
                  from any activities conducted under your account, your use or
                  misuse of our lottery platforms and, including but not limited
                  to posting content/playing games on our lottery platforms,
                  infringing any third party’s intellectual property or other
                  rights, or otherwise arising out of your breach of these Terms
                  and Conditions. 
                </p>
                <h3 className='text-xl font-semibold uppercase'>
                  Intellectual Property Rights
                </h3>
                <p>
                  The information, content, graphics, text, sounds, images,
                  buttons, trademarks, service marks, trade names and logos (the
                  "Materials") contained in this Website are protected by
                  copyright, trademark, database right, and other intellectual
                  property laws, under national laws.
                </p>
                <p>
                  You are granted a limited license solely for your own
                  personal, non-commercial use to refer to, bookmark or point to
                  any page within our lottery platforms, to download the
                  Materials contained on our lottery platforms to an
                  internet-enabled device, and to print a single hard copy of
                  the Materials contained on our lottery platforms for your
                  reference, provided however that all copyright, trademark and
                  other proprietary notices are left intact. The grant of this
                  limited license is conditional on your agreement to, and
                  compliance with the Terms and Conditions.  Any other use of
                  the Materials from our lottery platforms, including any form
                  of copying or reproduction (for any purposes other than those
                  noted above) modification, distribution, republication,
                  extraction, re-utilization, incorporation or integration with
                  other materials or works or re-delivery using framing
                  technology, without the prior written permission of MFL, is
                  strictly prohibited and violates the proprietary rights of MFL
                </p>
                <p>
                  Other rules may apply to certain software and other items
                  provided as part of our lottery platforms. Any special rules
                  will be listed as “legal notices” on our website and are
                  incorporated into these Terms and Conditions by this
                  reference.  All software that is made available for
                  downloading from our website (“Software”) is protected by
                  copyright and may be protected by other rights. You may only
                  use such Software to use our lottery platforms, and may not
                  copy, reproduce, distribute, or create derivative works based
                  on the Software.  Except as expressly permitted by applicable
                  law, you agree not to disassemble or otherwise reverse
                  engineer any of the Software for any reason or permit any
                  third party to do so.  The use of such Software may also be
                  governed by the terms in the software license agreement or
                  applicable “legal notice” accompanying such Software.  The
                  downloading and use of such Software is conditional on your
                  agreement to be bound by the terms of the relevant software
                  license agreement or designated “legal notice”.
                </p>
                <p>
                  Hypertext links to this Website are prohibited without the
                  prior written consent of MFL
                </p>
                <p>
                  All logos and all other trademarks registered by MFL are
                  trademarks of MFL.  All other trademarks, service marks, trade
                  names and logos contained in this Website belong to their
                  respective owners.
                </p>
                <p>
                  MFL and/or its licensors (as the case may be) retains all
                  right, title, interest, and intellectual property rights in
                  and to the Materials and the Software.  Nothing in these Terms
                  and Conditions shall be construed as conferring by
                  implication, estoppel or otherwise, any license or right under
                  any copyright, patent, trademark, database right, or other
                  intellectual property or proprietary interest of MFL, its
                  affiliates or any third party.
                </p>
                <h3 className='text-xl font-semibold uppercase'>
                  Availability
                </h3>
                <p>
                  Although MFL endeavours to ensure our lottery platforms are
                  available during gaming hours as determined by MFL from time
                  to time, there may be occasions when interruption may occur,
                  e.g., to allow maintenance, upgrades and emergency repairs to
                  take place, or due to failure of telecommunications links and
                  equipment that are beyond our control. You agree that MFL
                  shall not be liable to you for any loss incurred by you
                  resulting from the modification, suspension, or discontinuance
                  of our lottery platforms.{' '}
                </p>
                <h3 className='text-xl font-semibold uppercase'>
                  Links to other Websites 
                </h3>
                <p>
                  MFL makes no representations, warranties, or undertakings
                  about any content of or information on any other website
                  referred to or accessed by hypertext link through our lottery
                  platforms or from which our website is referred to or accessed
                  by hypertext link ("third party site").  MFL does not endorse
                  or approve the content of any third-party site, nor will MFL
                  have any liability in connection with any third-party site
                  (including but not limited to liability arising out of any
                  allegation that the content of or information on any
                  third-party site infringes any law or the rights of any person
                  or entity).  No judgment or warranty is made concerning the
                  accuracy, timeliness, or suitability of the content of any
                  third-party site, and any liability which might arise out of,
                  or in connection with, your use or reliance on the content of
                  or information on, or the performance of, any third-party site
                  is excluded.  Nor can MFL guarantee that the controller of any
                  third-party website will respect your privacy in the same
                  manner as MFL.
                </p>
                <p>
                  MFL reserves the right to change the content, presentation,
                  performance, use and facilities and availability of any part
                  of our lottery platforms at its sole discretion and without
                  liability to you, including the Terms and Conditions and you
                  should check these Terms and Conditions regularly for any
                  changes.
                </p>
                <p>Occasionally MFL may:</p>
                <p>
                  (a) change the technical specifications of our lottery
                  platforms or any portion thereof; or
                </p>
                <p>
                  (b) suspend our lottery platforms or any portion thereof for
                  operational reasons such as repair, maintenance, or
                  improvement of same or any portion thereof or because of an
                  emergency; or
                </p>
                <p>
                  (c) change the system resource limits applicable to your use
                  of our lottery platforms or any portion thereof.
                </p>
                <h3 className='text-xl font-semibold uppercase'>Waiver</h3>
                <p>
                  The failure of MFL to exercise or enforce any right or
                  provision of the Terms and Conditions shall not constitute a
                  waiver of such right or provision. If any provision of the
                  Terms and Conditions is found by a court of competent
                  jurisdiction to be invalid, the parties nevertheless agree
                  that the court should endeavour to give effect to the parties'
                  intentions as reflected in the provision, and the other
                  provisions of the Terms remain in full force and effect. The
                  Terms and Conditions represent the entire understanding and
                  agreement between you and MFL relating to the use of our
                  lottery platforms and supersede all prior statements,
                  understandings, or agreements whether oral or written.
                </p>
                <h3 className='text-xl font-semibold uppercase'>
                  Data Privacy Statement
                </h3>
                <p>
                  We respect and protect the privacy of the individuals who
                  access and use our lottery platforms. For full details of the
                  type of information we collect, how we use it, and under what
                  circumstances, refer to our data privacy policy statement on
                  our website.
                </p>
                <h3 className='text-xl font-semibold uppercase'>
                  Governing Law
                </h3>
                <p>
                  The Terms and Conditions shall be governed by and construed by
                  the laws of Ghana, and you hereby agree for the benefit of
                  MFL, and without prejudice to the right of MFL to take
                  proceedings concerning the Terms and Conditions before any
                  other court of competent jurisdiction, that the courts of
                  Ghana shall have jurisdiction to hear and determine any action
                  or proceedings that may arise out of or in connection with the
                  Terms and Conditions, and for such purposes, you irrevocably
                  submit to the exclusive jurisdiction of such courts.  
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions
