// import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
const LoginButton = ({setOpen}) => {
  // const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  return (
    // !isAuthenticated && (
      <button
        onClick={(e) => {
          e.preventDefault()
          navigate("/login")
          setOpen(false)
        }}
        className="bg-[#FF7300] rounded-3xl uppercase font-tommy text-white hover:bg-white hover:text-[#FF7300] border hover:border-[#FF7300] transition-all py-1 px-6"
      >
        Log in
      </button>
    // )
  );
};

export default LoginButton;
